import React from 'react';
import { Switch, FormControlLabel, Typography, Grid, Box } from '@mui/material';
import { useLanguage } from './LanguageContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { config } from "../../Config/config.service"

export default function LanguageSwitcher() {
  const { language } = useLanguage();
  // set defaultChecked to true if language is 'en' and false if language is 'de'
  console.log("language Switcher", language)
  let defaultChecked = language === 'en';


  const [checked, setChecked] = React.useState(defaultChecked);
  const { switchLanguage } = useLanguage();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleChange = (event) => {
    setChecked(event.target.checked);
    switchLanguage(event.target.checked ? 'en' : 'fr');
  };

  //   .leaflet-top {
  //     margin-right: 16.6667%;
  // }

  return (
    <div>
      <Typography component="div">
        <Box
          sx={{
            position: 'fixed', // Keeps the box fixed during scrolling
            // top: !mobile && 15, // Distance from the top of the viewport
            top: 75,
            // top: 60,
            right: config.general.rightDrawerWidthDesktop, // Distance from the right of the viewport
            mr: "10px",
            pl: 2,
            pr: 2,
            bgcolor: '#201c1c',
            borderRadius: '3px',
            display: 'inline-block',
            zIndex: 1000, // Ensure it is above other content
            '& > *': { opacity: 1 },
            // give it a boarder like this     border: 2px solid #94c11f;

            // border: "2px solid #94c11f",

          }}
        >
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item sx={{ color: '#94c11f' }}>FR</Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    name="checked"
                    sx={{
                      '& .MuiSwitch-thumb': { color: '#94c11f' },
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'black',
                        '& + .MuiSwitch-track': { backgroundColor: '#bfbfbf' },
                      },
                    }}
                  />
                }
                label=""
                style={{ marginRight: -9 }}
              />
            </Grid>
            <Grid item sx={{ color: '#94c11f' }}>EN</Grid>
          </Grid>
        </Box>
      </Typography>
    </div>
  );
}
