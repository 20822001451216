import { MenuItem, InputLabel, Box, FormControl, Select, SelectChangeEvent, CircularProgress } from "@mui/material";
import React from "react";
import { mapService } from "../../Services";
import { useGetSiteList } from "../../Hooks/useGetSiteList";
import useTranslation from "../Language/useTranslation";
import { useUserSelection } from '../../Contexts/useUserSelection';


interface SelectSiteProps {
    setMapBounds: React.Dispatch<React.SetStateAction<number[][]>>;

}

export function SelectSite({ setMapBounds }: SelectSiteProps) {
    const { selectedProjectarea, selectedSite, setSelectedSite } = useUserSelection();
    const siteDict = useGetSiteList(selectedProjectarea);

    const handleChange = (event: SelectChangeEvent) => {
        const siteId = event.target.value as string;
        setSelectedSite(siteId);
        mapService.zoomToPlace(siteId, setMapBounds, "site");
    };

    const t = useTranslation();

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth disabled={!selectedProjectarea}>
                <InputLabel id="site-select-label">{t.leftMainDrawer.siteSelection.selectionBox}</InputLabel>
                <Select
                    labelId="site-select-label"
                    id="site-select"
                    value={selectedSite ? selectedSite : ""}
                    label={t.leftMainDrawer.siteSelection.selectionBox}
                    onChange={handleChange}
                >
                    {Object.keys(siteDict).length === 0 &&
                        <MenuItem disabled>
                            <CircularProgress size={24} />
                        </MenuItem>}
                    {Object.entries(siteDict).map(([id, displayName]) => (
                        <MenuItem value={id} key={id}>{displayName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}




// import { MenuItem, InputLabel, Box, FormControl, Select, SelectChangeEvent } from "@mui/material";
// import React from "react";
// import { mapService } from "../../Services";
// import { useGetSiteList } from "../../Hooks/useGetSiteList";

// interface SelectSiteProps {
//     setSelectedSite: React.Dispatch<React.SetStateAction<string>>;
//     setMapBounds: React.Dispatch<React.SetStateAction<number[][]>>;
//     selectedProjectarea: string;
//     selectedSite: string;
// }
// export function SelectSite({ setSelectedSite, setMapBounds, selectedProjectarea, selectedSite }: SelectSiteProps) {
//     const siteList = useGetSiteList(selectedProjectarea);

//     const handleChange = (event: SelectChangeEvent) => {
//         setSelectedSite(event.target.value as string);
//         mapService.zoomToPlace(event.target.value as string, setMapBounds, "site");
//     };

//     return (
//         <Box sx={{ minWidth: 120 }}>
//             <FormControl fullWidth disabled={selectedProjectarea === "" ? true : false}>
//                 <InputLabel id="demo-simple-select-label">Site</InputLabel>
//                 <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={selectedSite}
//                     label="Site"
//                     onChange={handleChange}

//                 >
//                     {siteList.map((site) => {
//                         return (<MenuItem value={site} key={site}>{site}</MenuItem>);
//                     })}
//                 </Select>
//             </FormControl>
//         </Box>
//     );
// }
