// conversion functions based on Tim Down's answer in https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function rgbToHex(r: number, g: number, b: number) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function calculateColorGradientValue(fromColorHex: string, toColorHex: string, minValue: number, maxValue: number, actualValue: number, defaultColor: string) {
    let fromColorRgb = hexToRgb(fromColorHex);
    let toColorRgb = hexToRgb(toColorHex);

    let scale = (actualValue - minValue) / (maxValue - minValue)
    if (scale < 0) {
        scale = 0;
    } else if (scale > 1) {
        scale = 1;
    }

    return (fromColorRgb !== null && toColorRgb !== null) ?
        rgbToHex(
            Math.round(fromColorRgb.r + scale * (toColorRgb.r - fromColorRgb.r)),
            Math.round(fromColorRgb.g + scale * (toColorRgb.g - fromColorRgb.g)),
            Math.round(fromColorRgb.b + scale * (toColorRgb.b - fromColorRgb.b))
        ) : defaultColor;
}