// src/Hooks/useGetLayerImage.ts
import { useEffect, useState } from 'react';
import dummyImage from '../Images/Thumbnails/dummy_layer.png';
import { backendService } from "../Services";
import { useLanguage } from '../components/Language/LanguageContext';

export function useGetLayerImage(imgLink?: string): string {
    const [imageSrc, setImageSrc] = useState<string>(dummyImage);
    const { language } = useLanguage();
    useEffect(() => {
        let isMounted = true; // Prevent state update if component is unmounted
        const fetchImage = async () => {
            if (imgLink) {
                try {
                    const src = await backendService.getLayerImage(imgLink, language);
                    if (isMounted) {
                        setImageSrc(src);
                    }
                } catch (error) {
                    console.error("Failed to fetch layer image:", error);
                    if (isMounted) {
                        setImageSrc(dummyImage); // Fallback image
                    }
                }
            }
        };
        fetchImage();

        return () => {
            isMounted = false;
        };
    }, [imgLink]);

    return imageSrc;
}