import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { useGetStatisticsInfo } from '../../Hooks/useGetSatelliteStatistics';
import { StatisticsSatellite } from '../../Services/apiResponseInterfaces';
import { useUserSelection } from '../../../../Contexts/useUserSelection';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DashboardCard from '../../../Cards/DashboardCard.component';
import useTranslation from '../Language/useTranslation';


interface PieChartCustomProps {
    data: StatisticsSatellite;
    height: number;
}

const LandCoverPieChart: React.FC<PieChartCustomProps> = ({ data, height }) => {
    const pieData = data.labels?.map((label: string, index: number) => ({
        name: label,
        value: data.values?.[index],
        color: data.colors?.[index],
        shares: data.shares?.[index]
    })) || [];
    const t = useTranslation();

    return (
        <Grid container>
            <Grid item xs={12} container justifyContent="center">
                <Typography variant="h6" gutterBottom>
                    {t.bottomDrawer.satelliteData.satStats.diagram}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={height}>

                    <PieChart >
                        <Pie
                            data={pieData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={({ percent }) => `${(percent * 100).toFixed(2)}%`}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="shares"
                        >
                            {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip formatter={(value: number) => `${value} %`} />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default LandCoverPieChart;