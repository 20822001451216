import React from "react"
import ErrorDialog from "./ErrorDialog.component";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { error: true };
  // }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return <ErrorDialog errorTitle={this.state.error.toString()} errorText={this.state.errorInfo.componentStack}></ErrorDialog>;
    }

    return this.props.children;
  }
}