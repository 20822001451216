import { useEffect, useState } from "react";
import { backendService } from "../Services";
import { CountryData } from "../Services/apiResponseInterfaces";
import { useLanguage } from "../components/Language/LanguageContext";


export function useGetCountryInfoList() {
    const [countryInfoList, setCountryInfoList] = useState<CountryData[]>([]);
    const { language } = useLanguage();


    useEffect(() => {
        backendService.getCountries(language)
            .then((data) => {
                const processedData: CountryData[] = data.map((item: any) => {
                    const countryInfo: CountryData = {
                        name: item.name,
                        bounds: item.bounds,
                        num_sites: item.num_sites,
                        total_site_area_in_ha: item.total_site_area_in_ha,
                        native_species: item.native_species,
                        non_native_species: item.non_native_species,
                        activity_types: item.activity_types,
                        tenure_types: item.tenure_types,
                        num_trees_planted: item.num_trees_planted,
                        avg_tree_survival_rate: item.avg_tree_survival_rate,
                        code: item.code,
                        num_project_areas: item.num_project_areas,
                    };

                    // Add any additional properties
                    for (const key in item) {
                        if (!(key in countryInfo)) {
                            countryInfo[key] = item[key];
                        }
                    }

                    return countryInfo;
                });

                setCountryInfoList(processedData);
            })
            .catch((e) => {
                console.error("Error when resolving country list:", e);
            });
    }, [language]);

    return countryInfoList;
}
