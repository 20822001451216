import { useState, useEffect } from 'react';
import { DroneLayer, DroneLayersList } from '../Services/apiResponseInterfaces';
import { backendService } from '../Services';
import { useLanguage } from '../components/Language/LanguageContext';

export function useGetDroneRasterLayerInfo(site: string | null) {
  const [droneLayerList, setDroneLayerList] = useState<DroneLayersList>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { language } = useLanguage();

  useEffect(() => {
    if (!site) return;

    const parseDataset = (data: any): DroneLayer => {
      return {
        dataset_id: data.dataset_id,
        name: data.name,
        description: data.description,
        region_id: data.region_id,
        region_level: data.region_level,
        layer_type: data.layer_type,
        value_type: data.value_type,
        format: data.format,
        provider: data.provider,
        attribution: data.attribution,
        all_touched_clipping: data.all_touched_clipping,
        buffer_in_m: data.buffer_in_m,
        resolution: data.resolution,
        unit: data.unit,
        bidx: data.bidx,
        nodata: data.nodata,
        colormap_name: data.colormap_name,
        legend: data.legend,
        imgLink: data.imgLink,
        layers: data.layers?.map((layer: any) => ({
          dataset_id: layer.dataset_id,
          region_id: layer.region_id,
          region_level: layer.region_level,
          date_str: layer.date_str,
          link: layer.link,
          id: layer.id,
          path_id: layer.path_id,
          buffered: layer.buffered,
          rescale: layer.rescale,
          statistics: layer.statistics?.map((stat: any) => ({
            stat_short_name: stat.stat_short_name,
            stat_long_name: stat.stat_long_name,
            operation: stat.operation,
            date: stat.date,
            description: stat.description,
            stat_class: stat.stat_class,
            mean: stat.mean,
            unit: stat.unit,
            text_value: stat.text_value
          })),
          ...layer // Catch-all for unknown properties
        })),
        ...data // Catch-all for unknown properties
      };
    };

    const parseDatasetList = (dataList: any[]): DroneLayersList => {
      // sort by dataset id
      dataList.sort((a, b) => a.dataset_id - b.dataset_id);
      return dataList.map(parseDataset);
    };

    const fetchDroneLayers = async () => {
      setLoading(true);
      try {
        const droneData = await backendService.getDroneLayersForSite(site, language);
        setDroneLayerList(parseDatasetList(droneData));
      } catch (error) {
        console.error("Error fetching drone layer info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDroneLayers();
  }, [site, language]);

  return { droneLayerList, loading };
}