import Map from '../Map/Map';
import { useState, useEffect, createContext } from 'react';
import BottomDrawer from '../BottomDrawer/BottomDrawer.component';
import { config } from "../../Config/config.service" // All configurations, response objects as typescript objects
import LeftMainDrawer from '../LeftMainDrawer/LeftMainDrawer';
import RightMainDrawer from '../RightMainDrawer/RightMainDrawer';
import { useGetDroneRasterLayerInfo } from '../../Hooks/useGetDroneRasterLayerInfo';
import { useGetSatelliteRasterLayerInfo } from '../../Hooks/useGetSatelliteRasterLayerInfo';
import LogoDrawer from '../LogoDrawer/LogoDrawer';
import { LatLngBoundsExpression } from 'leaflet';
import L from 'leaflet';
import { CalculatedLayer } from '../../Services/apiResponseInterfaces';
import { useGetDroneStatistics } from '../../Hooks/useGetDroneStatistics';
import { Box, Button } from '@mui/material';

import { useUserSelection } from '../../Contexts/useUserSelection';
import CustomDevelopmentToolkit from '../Development/CustomDevelopmentToolkit';

export default function MainPage() {
    const [bottomDrawerOpen, setBottomDrawerOpen] = useState<boolean>(false)
    const [rightMainDrawerOpen, setRightMainDrawerOpen] = useState<boolean>(true)
    var southWest = L.latLng(0, 0), northEast = L.latLng(0, 0)
    const [mapBounds, setMapBounds] = useState<L.LatLngBounds>(new L.LatLngBounds(southWest, northEast))
    // Satellite Calculated NDVI Layer
    const [calculatedLayer, setCalculatedLayer] = useState<CalculatedLayer | null>(null);
    const [selectedLayer, setSelectedLayer] = useState<string | null>(null);

    // Get user selection from context
    const { selectedCountry, setSelectedCountry, selectedProjectarea, setSelectedProjectarea, selectedSite, setSelectedSite } = useUserSelection();

    let { droneLayerList, loading: droneLoading } = useGetDroneRasterLayerInfo(selectedSite)
    // Initial data fetch
    let { satLayerList, loading: satLoading } = useGetSatelliteRasterLayerInfo(selectedSite)



    // const [jumpToSite, setJumpToSite] = useState<boolean>(false)
    // useEffect(() => {
    //     if (jumpToSite) {
    //         setSelectedCountry("mad")
    //         setSelectedProjectarea("giz_diana")
    //         setSelectedSite("mdg_giz_diana_15")
    //         // setSelectedSite("mdg_giz_diana_15")

    //         setMapBounds(new L.LatLngBounds(
    //             L.latLng(-12.443756409060736, 49.24178750903255),
    //             L.latLng(-12.442122316226904, 49.24418830105677)
    //         ));
    //     }
    // }, [jumpToSite])
    ////////////////////////////


    return (

        <>
            {/* Remove for Production*/}
            {/* <CustomDevelopmentToolkit
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                jumpToSite={jumpToSite}
                setJumpToSite={setJumpToSite}
            /> */}
            {/* Remove for Production*/}

            <LeftMainDrawer
                setMapBounds={setMapBounds}
                setBottomDrawerOpen={setBottomDrawerOpen}
                setRightMainDrawerOpen={setRightMainDrawerOpen}
            />
            <LogoDrawer />
            <RightMainDrawer
                open={rightMainDrawerOpen}
            />
            <Map
                mapBounds={mapBounds}
                selectedLayer={selectedLayer}
                setSelectedLayer={setSelectedLayer}
                calculatedLayer={calculatedLayer}
                droneLayerList={droneLayerList}
                droneLoading={droneLoading}
                satLayerList={satLayerList}
                satLoading={satLoading}
                setMapBounds={setMapBounds}
            />
            <BottomDrawer
                bottomDrawerOpen={bottomDrawerOpen}
                setBottomDrawerOpen={setBottomDrawerOpen}
                setSelectedLayer={setSelectedLayer}
                selectedLayer={selectedLayer}
                calculatedLayer={calculatedLayer}
                setCalculatedLayer={setCalculatedLayer}
                droneLayerList={droneLayerList}
                droneLoading={droneLoading}
                satLayerList={satLayerList}
                satLoading={satLoading}
            />
        </>

    )
}

