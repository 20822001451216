import React from 'react';
import { Box, Grid } from '@mui/material';
import BarChartContent from '../../../Charts/BarChart.component';
import ScatterChartContent from '../../../Charts/ScatterChart.component';
import { useTheme } from '@mui/material/styles';

interface ChartBoxProps {
    trendType: string;
    chartResult: any;
}

const ChartBox: React.FC<ChartBoxProps> = ({ trendType, chartResult }) => {
    const theme = useTheme();
    const chartHeight = 200

    return (
        <Box sx={{ pb: 1, overflow: 'auto' }}>
            <Grid container sx={{ paddingTop: 1 }} justifyContent="flex-start">
                <Grid item xs={12} sx={{ borderRadius: 0 }}>
                    {trendType === "NDVI_CHANGE_SINCE_PROJECT_START" && (
                        <BarChartContent
                            result={chartResult}
                            chartName="NDVI"
                            xAxisDataKey="date"
                            yAxisDataKey="ndvi"
                            height={chartHeight}
                            width={600}
                        />
                    )}
                    {trendType === "NDVI" && (
                        <ScatterChartContent
                            result={chartResult}
                            chartName="NDVI max"
                            xAxisDataKey="date"
                            yAxisDataKey="ndvi"
                            lowColor={theme.palette.redCustom.main}
                            dataValueForLowColor={0}
                            highColor={theme.palette.primary.main}
                            dataValueForHighColor={1}
                            defaultColor={theme.palette.primary.main}
                            height={chartHeight}
                            width={600}
                        />
                    )}
                    {trendType === "NDWI" && (
                        <ScatterChartContent
                            result={chartResult}
                            chartName="NDWI"
                            xAxisDataKey="date"
                            yAxisDataKey="ndwi"
                            lowColor={theme.palette.redCustom.main}
                            dataValueForLowColor={0}
                            highColor={theme.palette.blueCustom.main}
                            dataValueForHighColor={1}
                            defaultColor={theme.palette.blueCustom.main}
                            height={chartHeight}
                            width={600}
                        />
                    )}
                    {trendType === "Rainfall" && (
                        <BarChartContent
                            result={chartResult}
                            chartName="Rainfall [mm/m²]"
                            xAxisDataKey="date"
                            yAxisDataKey="rainfall"
                            height={chartHeight}
                            width={600}
                        />
                    )}
                    {trendType === "FIRE" && (
                        <ScatterChartContent
                            result={chartResult}
                            chartName="Fire count"
                            xAxisDataKey="date"
                            yAxisDataKey="ndwi"
                            lowColor={theme.palette.redCustom.main}
                            dataValueForLowColor={0}
                            highColor={theme.palette.redCustom.main}
                            dataValueForHighColor={1}
                            defaultColor={theme.palette.redCustom.main}
                            height={chartHeight}
                            width={600}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChartBox;
