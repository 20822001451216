import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import dfsLogo from "../../Images/Logos/dfs-logo-white.png";
import gizLogo from "../../Images/Logos/giz-logo.png";
import rssLogo from "../../Images/Logos/logoRssLight.png";


export default function LogoDrawer() {
    const logos = [gizLogo, rssLogo, dfsLogo]
    const sizes = ["90px", "60px", "60px"]
    const margins = ["0 0 1 1", 1, 1]

    return (
        <Box
            position="absolute"
            sx={{ bottom: 0, right: 0, marginBottom: 5, marginRight: 2, zIndex: 1000 }}
        >
            <Grid >
                {
                    logos.map((logo, index) =>
                        <Box
                            key={index}
                            component="img"
                            sx={{
                                m: margins[index],
                                width: sizes[index]
                            }}
                            src={logo}
                        />
                    )
                }
            </Grid>
        </Box>
    )
}
