import L, { LatLngBounds } from 'leaflet';
import { useState } from "react";

import "leaflet.vectorgrid";
import { config } from '../../../Config/config.service';
import { mapService } from '../../../Services';

import { useTheme } from '@mui/material/styles';

var core = require("@react-leaflet/core");

function createMVT(props: any, context: any) {
    const instance = L.vectorGrid.protobuf(props.url, props.options);
    return {
        instance: instance,
        context: { ...context, overlayContainer: instance },
    };
}

function updateMVT(instance: any, props: any, prevProps: any) { }

const ShapeLayerComponent = core.createPathComponent(createMVT, updateMVT);

interface ShapeLayerProps {
    // setShowPopup: Function,
    // setPolygonInfo: Function,
    layerUrl: string
    regionType: string // required to zoom to a place
    idProppertyName: string // required to get the ID name of a layer to zoom to the layer
    // to ensure that lower hierarchy layers remain clickable even though two layers are visible (e.g. projectarea over country)
    zIndexOffset: number
    setMapBounds: React.Dispatch<React.SetStateAction<LatLngBounds>>,
}

export function ShapeLayer({ layerUrl, regionType, idProppertyName, zIndexOffset, setMapBounds }: ShapeLayerProps) {
    const theme = useTheme();

    const layerStyles = {
        default: function (properties: any, zoom: any) {
            return {
                stroke: true,
                color: "white",
                weight: 3,
            };
        },
    };

    return (
        <ShapeLayerComponent
            url={layerUrl}
            options={{
                rendererFactory: L.canvas.tile,
                vectorTileLayerStyles: layerStyles,
                zIndex: 1000 + zIndexOffset,
            }}
        />);
}