import { Grid, AppBar, Typography, Toolbar, Box, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { config } from "../../Config/config.service"
import f4fLogo from "../../Images/Logos/f4f_logo.png";
import LeftMainDrawerContent from './LeftMainDrawerContent.component';
import { useUserSelection } from '../../Contexts/useUserSelection';
import { useTheme } from '@mui/material/styles';


interface LeftMainDrawerProps {
    setMapBounds: React.Dispatch<React.SetStateAction<L.LatLngBounds>>,
    setBottomDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setRightMainDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function LeftMainDrawer({
    setMapBounds,
    setBottomDrawerOpen,
    setRightMainDrawerOpen }: LeftMainDrawerProps) {




    return (
        <SidebarDrawer
            transitionDuration={0}
            variant="persistent"
            anchor="left"
            open
        >
            <>
                <CustomDrawerHeader
                    drawerWidth={config.general.leftDrawerWidthDesktop}
                />
                <Box p={2}>
                    <LeftMainDrawerContent
                        setMapBounds={setMapBounds}
                        setBottomDrawerOpen={setBottomDrawerOpen}
                        setRightMainDrawerOpen={setRightMainDrawerOpen}
                    />
                </Box>
            </>
        </SidebarDrawer>
    )
}

function CustomDrawerHeader(props: any) {
    const theme = useTheme();

    return (
        <DrawerHeader>
            <AppBar
                position="fixed"
                sx={{
                    // width: `${props.drawerWidth}px`,
                    // mr: `calc(100% - ${props.drawerWidth}px)`
                    width: "25%",
                    mr: `calc(100% - 25%)`,
                    color: theme.palette.primary.main // Set primary color
                }}
            >
                <Toolbar>
                    <Grid container justifyContent="space-around" alignItems="center">
                        <Box
                            component="img"
                            sx={{
                                // m: 1,
                                width: "30%"
                            }}
                            src={f4fLogo}
                        />
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                            Monitoring Platform
                        </Typography>
                        <></>
                    </Grid>
                </Toolbar>
            </AppBar>
        </DrawerHeader>
    );

}

const SidebarDrawer = styled(Drawer, {
})({
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: config.general.leftDrawerWidthDesktop,
        height: config.general.leftDrawerHeight,
        boxSizing: 'border-box',
        borderRadius: "0px 0px 8px 0px"
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
