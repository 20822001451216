import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

interface BarChartContentProps {
    result: any,
    xAxisDataKey: string,
    yAxisDataKey: string,
    chartName: string,
    height: number,
    width: number
}

export default function BarChartContent({ result, xAxisDataKey, yAxisDataKey, chartName: xAxisLabel, height, width }: BarChartContentProps) {
    var timeseriesData: any[];
    var timeseriesData = []
    const theme = useTheme();

    try {
        // convert result to dict so it can be shown with Recharts
        _.zipWith(result.data, result.time_steps, function (value: number, timestep: string) {
            if (yAxisDataKey === "ndvi") {
                timeseriesData.push(
                    {
                        [xAxisDataKey]: timestep.slice(0, 4),
                        [yAxisDataKey]: Math.round(value * 100) / 100
                    }
                )
            } else {

                const formattedTimestep = dayjs(timestep).format('MMM YYYY'); // Format the date to "Month Year"

                timeseriesData.push(
                    {
                        [xAxisDataKey]: formattedTimestep,
                        [yAxisDataKey]: Math.round(value * 100) / 100
                    }
                )
            }
        });

    } catch (e: any) {
        console.log(e)
    }
    return (
        <ResponsiveContainer width="100%" height={height}>
            < BarChart
                // width={width}
                //     height={height}
                data={timeseriesData}
                margin={{
                    top: 5,
                    right: 10,
                    left: 10,
                    bottom: 5,
                }
                } >
                <XAxis dataKey={xAxisDataKey} />
                <YAxis yAxisId="right" orientation="right" />

                <Tooltip />
                <Legend />
                {
                    (yAxisDataKey === "ndvi") ?
                        <Bar dataKey={yAxisDataKey} fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth={2} name={xAxisLabel} yAxisId="right" />
                        :
                        <Bar dataKey={yAxisDataKey} fill="#8884d8" stroke="#8884d8" strokeWidth={2} name={xAxisLabel} yAxisId="right" />
                }
            </BarChart >
        </ResponsiveContainer >
    );

}
