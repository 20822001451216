import { MenuItem, InputLabel, Box, FormControl, Select, SelectChangeEvent, CircularProgress } from "@mui/material";
import React from "react";
import { mapService } from "../../Services";
import { useGetProjectareaList } from "../../Hooks/useGetProjectareaList";
import useTranslation from "../Language/useTranslation";
import { useUserSelection } from '../../Contexts/useUserSelection';

interface SelectProjectareaProps {
    setMapBounds: React.Dispatch<React.SetStateAction<number[][]>>;
}

export function SelectProjectarea({ setMapBounds }: SelectProjectareaProps) {
    const { selectedCountry, selectedProjectarea, setSelectedProjectarea } = useUserSelection();
    const { projectareasInfo, isLoading } = useGetProjectareaList(selectedCountry);
    const handleChange = (event: SelectChangeEvent) => {
        const projectAreaCode = event.target.value as string;
        setSelectedProjectarea(projectAreaCode);
        mapService.zoomToPlace(projectAreaCode, setMapBounds, "projectarea");
    };
    const t = useTranslation();

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth disabled={!selectedCountry}>
                <InputLabel id="project-area-select-label">{t.leftMainDrawer.projectareaSelection.selectionBox}</InputLabel>
                <Select
                    labelId="project-area-select-label"
                    id="project-area-select"
                    value={selectedProjectarea ? selectedProjectarea : ""}
                    label={t.leftMainDrawer.projectareaSelection.selectionBox}
                    onChange={handleChange}
                >
                    {isLoading ? (
                        <MenuItem disabled>
                            <CircularProgress size={24} />
                        </MenuItem>
                    ) : (
                        Object.entries(projectareasInfo ?? {}).map(([code, name]) => (
                            <MenuItem value={code} key={code}>{name}</MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}