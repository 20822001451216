// src/components/Cards/LayerSelectionCard.tsx
import React, { useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, IconButton, Badge } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useGetLayerImage } from '../../Hooks/useGetLayerImage'; // Adjust the import path as needed
import LayerInfo from '../Dialog/LayerInfo';
import { useTheme } from '@mui/material/styles';
import useTranslation from '../Language/useTranslation';
import ndvi_calculated from '../../Images/Thumbnails/ndvi_calculated.png'; // Adjust the import path as needed

interface LayerSelectionCardProps {
    datasetId: string | undefined;
    selectedLayer: string | null | undefined;
    setSelectedLayer: React.Dispatch<React.SetStateAction<string | null>>;
    layerType: string | undefined;
    imgLink?: string;
    layerDescription: string | null | undefined;
    name: string | undefined;
    isNew?: boolean; // New optional prop to indicate if the layer is newly added
}

const LayerSelectionCard: React.FC<LayerSelectionCardProps> = ({
    datasetId,
    selectedLayer,
    setSelectedLayer,
    layerType,
    layerDescription,
    imgLink,
    name,
    isNew = false }) => {
    const [showInfo, setShowInfo] = useState(false);

    let imageSrc = null
    imageSrc = useGetLayerImage(imgLink);

    if (datasetId === "NDVI_CHANGE_SINCE_PROJECT_START") {
        imageSrc = ndvi_calculated
    }

    const handleClickLayer = () => {
        if (!showInfo) {
            setSelectedLayer(datasetId === undefined || selectedLayer === datasetId ? null : datasetId);
        }
    };

    const handleClickInfo = (e: React.MouseEvent) => {
        setShowInfo(true);
        e.stopPropagation();
    };

    const theme = useTheme();
    const t = useTranslation();

    return (
        <>
            {showInfo && (
                <LayerInfo
                    setShowInfo={setShowInfo}
                    name={name}
                    layerDescription={layerDescription}
                />
            )}
            <Card
                onClick={handleClickLayer}
                sx={{
                    border: 1,
                    borderColor: 'transparent',
                    '&:hover': { cursor: 'pointer', border: 1, borderColor: theme.palette.primary.main },
                    ...(selectedLayer === datasetId && { borderColor: theme.palette.primary.main }),
                }}
            >
                <CardContent
                    sx={{
                        padding: 1.5,
                        '&:last-child': {
                            paddingBottom: 1.5,
                        },
                    }}
                >
                    <Grid container justifyContent="space-between" alignItems="center" direction="row" height="50px" p={0}>
                        <Grid item container justifyContent="flex-start" alignItems="center" sm={3}>
                            <Badge
                                color="primary"
                                badgeContent={t.extras.badge}
                                invisible={!isNew} // Only show the badge if the layer is new
                                sx={{
                                    '& .MuiBadge-badge': {
                                        fontSize: '0.6rem', // Adjusts the font size of the badge content
                                        minWidth: '12px',   // Minimum width of the badge
                                        height: '16px',     // Height of the badge
                                        padding: '0 4px',   // Padding inside the badge
                                        transform: 'translate(18px, -10px)', // Moves the badge 10px to the left


                                    },
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 1,
                                    }}
                                    src={imageSrc}
                                />
                            </Badge>
                        </Grid>

                        <Grid item container alignItems="center" direction="row" sm={9}>
                            <Grid item sm={10}>
                                <Typography variant="subtitle1" color="text.secondary" mr={2} m={0} lineHeight={1.5}
                                    sx={{
                                        color: selectedLayer === datasetId ? theme.palette.primary.main : undefined,
                                    }}>
                                    {name !== undefined
                                        ? name.length >= 30
                                            ? name.substring(0, 27) + "..."
                                            : name
                                        : "unlabeled"}
                                </Typography>
                            </Grid>
                            <Grid item sm={2} container justifyContent="flex-end" alignItems="center">
                                <IconButton
                                    sx={{ padding: 0 }}
                                    onClick={handleClickInfo}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </>
    );
};

export default LayerSelectionCard;