import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {

  const browserLanguage = navigator.language || navigator.userLanguage;
  let defaultLanguage = 'fr';
  // if (!browserLanguage.startsWith('de')) {
  //   defaultLanguage = 'en';
  // }


  const [language, setLanguage] = useState(defaultLanguage); // default language
  const switchLanguage = (lang) => setLanguage(lang);

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);