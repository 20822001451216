import L, { LatLngBounds } from 'leaflet';
import { useState } from "react";

import "leaflet.vectorgrid";
import { config } from '../../../Config/config.service';
import { mapService } from '../../../Services';

import { useTheme } from '@mui/material/styles';
import { useUserSelection } from '../../../Contexts/useUserSelection';

var core = require("@react-leaflet/core");

function createMVT(props: any, context: any) {
    const instance = L.vectorGrid.protobuf(props.url, props.options);
    return {
        instance: instance,
        context: { ...context, overlayContainer: instance },
    };
}

function updateMVT(instance: any, props: any, prevProps: any) { }

const SelectedShapeLayerComponent = core.createPathComponent(createMVT, updateMVT);

interface ShapeLayerProps {
    // setShowPopup: Function,
    // setPolygonInfo: Function,
    layerUrl: string
    regionType: string // required to zoom to a place
    idProppertyName: string // required to get the ID name of a layer to zoom to the layer
    selectedRegion: string | null
    // to ensure that lower hierarchy layers remain clickable even though two layers are visible (e.g. projectarea over country)
    zIndexOffset: number
    setMapBounds: React.Dispatch<React.SetStateAction<LatLngBounds>>,
    setSelectedRegion: React.Dispatch<React.SetStateAction<string | null>>,
}

export function SelectedShapeLayer({ layerUrl, regionType, idProppertyName, selectedRegion, zIndexOffset, setMapBounds, setSelectedRegion }: ShapeLayerProps) {
    const theme = useTheme();
    const { selectedCountry, setSelectedCountry, selectedProjectarea, setSelectedProjectarea, selectedSite, setSelectedSite } = useUserSelection();


    console.log("selected", selectedCountry, selectedProjectarea, selectedSite)



    const layerStyles = {
        default: function (properties: any, zoom: any) {
            // Ref: https://leafletjs.com/reference-1.7.1.html#path
            // check if region selected
            if (!selectedRegion) {
                selectedRegion = ""
            };
            if (properties[idProppertyName] == selectedRegion) {
                return {
                    stroke: true,
                    color: theme.palette.primary.main,
                    weight: 5,
                }
            } else {
                return {
                    stroke: false
                }
            }
        },
    };

    return (
        <SelectedShapeLayerComponent
            key={selectedRegion}
            url={layerUrl}
            options={{
                rendererFactory: L.canvas.tile,
                vectorTileLayerStyles: layerStyles,
                interactive: true,
                zIndex: 1000 + zIndexOffset,
            }}
            eventHandlers={{
                click: (e: any) => {
                    //TODO: use a title property in the DB and use that for displaying purposes
                    let selectedRegion = e.layer.properties[idProppertyName] as string

                    setSelectedRegion(selectedRegion)
                    mapService.zoomToPlace(e.layer.properties[idProppertyName] as string, setMapBounds, regionType)

                    // if(!!props.setSelectedPolygonId) {props.setSelectedPolygonId(e.layer.properties.id);}
                    // setPolygonInfo(e.layer.properties)
                    // setShowPopup(true)
                },
            }}
        />);
}