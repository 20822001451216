import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import greens from '../../Images/Legends/greens.png';
import schwarzwald from '../../Images/Legends/schwarzwald.png';
import rdylgn from '../../Images/Legends/rdylgn.png';
import rain from '../../Images/Legends/rain.png';
import { DroneLayersList, SatLayerList } from '../../Services/apiResponseInterfaces';
import { getSelectedDataset } from '../../utils/data';
interface LegendSimpleProps {
    droneLayerList: DroneLayersList,
    satLayerList: SatLayerList,
    selectedLayer: string | null,
}


export default function DynamicLegend({
    droneLayerList,
    satLayerList,
    selectedLayer }: LegendSimpleProps) {


    let legendImages: { [key: string]: string } = {};

    legendImages = {
        "schwarzwald": schwarzwald,
        "greens": greens,
        "rdylgn": rdylgn,
        "rain": rain
    }

    interface LegendInfo {
        rescaleMin: string;
        rescaleMax: string;
        unit: string,
        image: any;
    }

    let legendInfo: LegendInfo = {
        rescaleMax: "",
        rescaleMin: "",
        unit: "",
        image: null
    }




    function setLegendInfo(selectedLayer: string | null) {
        console.log("satLayerList", satLayerList)
        console.log("selectedLayer", selectedLayer)
        if (!selectedLayer || selectedLayer === "uav_orthophoto") return;

        if (selectedLayer.startsWith("uav")) {
            const droneLayerInfo = getSelectedDataset(droneLayerList, selectedLayer);
            if (!droneLayerInfo) return;

            const rescale = droneLayerInfo.layers?.[0]?.rescale?.[0]?.split(",") || [];
            const rescaleMin = rescale[0];
            const rescaleMax = rescale[1];

            legendInfo.image = legendImages[droneLayerInfo.colormap_name] || "";
            legendInfo.unit = droneLayerInfo.unit || "";

            if (selectedLayer === "uav_chm" && rescaleMin && rescaleMin < 0) {
                legendInfo.rescaleMin = "0";
            } else {
                legendInfo.rescaleMin = rescaleMin || "";
            }
            legendInfo.rescaleMax = rescaleMax || "";

        } else if (selectedLayer.startsWith("sat") && selectedLayer !== "sat_planet") {
            const satelliteLayerInfo = getSelectedDataset(satLayerList, selectedLayer);
            legendInfo.image = legendImages[satelliteLayerInfo.colormap_name]
            legendInfo.unit = satelliteLayerInfo.unit
            legendInfo.rescaleMin = satelliteLayerInfo.layers && satelliteLayerInfo.layers[0]?.rescale?.[0]?.split(",")[0]
            legendInfo.rescaleMax = satelliteLayerInfo.layers && satelliteLayerInfo.layers[0]?.rescale[0].split(",")[1]

        } else if (selectedLayer === "NDVI_CHANGE_SINCE_PROJECT_START") {
            legendInfo.image = legendImages["rdylgn"]
            legendInfo.unit = ""
            legendInfo.rescaleMin = "Vegetation Loss"
            legendInfo.rescaleMax = "Vegetation Gain"
        } else {
            return
        }
    }

    setLegendInfo(selectedLayer)
    if (!legendInfo.image) return <></>;

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    zIndex: 1000,
                    m: 2,
                    left: "50%",
                    ml: "15px", // (drawerWidth/2)- (width/2)  
                    width: "320px"
                }}
            >
                <Grid>
                    <Paper elevation={3} square={true}>
                        <Box
                            component="img"
                            sx={{
                                // m: 1,
                                // width: "70vw",
                                p: 1,
                                pl: 2,
                                pr: 2,
                                width: "100%",
                            }}
                            src={legendInfo.image}
                        />
                    </Paper>
                    <Paper elevation={3} square={true}>
                        <Grid container direction="row" justifyContent="space-between" paddingLeft={1} paddingRight={1}>
                            <Grid item>
                                <Typography variant="body2" color="text.secondary" mb={1}>
                                    {legendInfo.rescaleMin} {legendInfo.unit}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="text.secondary" mb={1}>
                                    {legendInfo.rescaleMax} {legendInfo.unit}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Box >
        </>
    )
}


