import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import KeyboardDoubleArrowDown from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUp from '@mui/icons-material/KeyboardDoubleArrowUp';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import { config } from "../../Config/config.service";

interface DesktopBottomDrawerProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    children: React.ReactNode
}

export default function DesktopBottomDrawer({ open, setOpen, children }: DesktopBottomDrawerProps) {
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{
            ".MuiDrawer-root > .MuiPaper-root": {
                height: config.general.bottomDrawerHeight, //`calc(50% - ${drawerBleeding}px+20px)`
                overflow: "visible",
                backgroundColor: 'customBackground.main',
            }
        }}>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}
                swipeAreaWidth={56}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                variant="persistent" //remove to activate swipe behaviour
            >
                <Box
                    sx={{
                        position: 'relative',
                        height: '100%',
                    }}
                >
                    <OpenCloseDrawerElement toggleDrawer={toggleDrawer} open={open} />
                    <Box
                        sx={{
                            px: 2,
                            // pb: 2,
                            // pt: 7, // Padding top to avoid overlap
                            height: `calc(100%)`, // Adjust height to be scrollable
                            overflowY: 'auto',
                        }}
                    >

                        {children}
                    </Box>
                </Box>
            </SwipeableDrawer>
        </Box>
    );
}

function OpenCloseDrawerElement({ toggleDrawer, open }: { toggleDrawer: () => void, open: boolean }) {
    const widthBleeding = 150;
    const marginLeft = (-widthBleeding / 2); //(drawerWidth/2)- (width/2)  
    const drawerBleeding = 56;
    return (
        <Box
            sx={{
                position: 'absolute',
                top: -drawerBleeding + 7,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                width: `${widthBleeding}px`,
                ml: `${marginLeft}px`,
                left: "50%",
                backgroundColor: 'customBackground.main',
                zIndex: (theme) => theme.zIndex.drawer + 2, // Ensure it's above the drawer
            }}
        >
            <Grid container justifyContent="center">
                <IconButton onClick={toggleDrawer}>
                    {open ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowUp />}
                </IconButton>
            </Grid>
            <Container sx={{ p: 1 }}></Container>
        </Box>
    );
}