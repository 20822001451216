import { config } from "../Config/config.service";
import axios from 'axios';
import { getEndOfCurrentMonth, getEndOfLastYear, getTrendStartDate } from "../utils/dateUtils";
import { GeoJsonObject } from 'geojson';

interface Params {
    lang?: string;
}

export class BackendService {
    private defaultLanguage = 'en';
    private oldBackend = false;

    private baseURL;
    private old_backendURL;
    constructor(baseURL: string, old_backendURL: string) {
        if (baseURL.startsWith("/")) {
            this.baseURL =
                window.location.protocol + "//" + window.location.host + baseURL;
            this.old_backendURL =
                window.location.protocol + "//" + window.location.host + old_backendURL;
        } else {
            this.baseURL = baseURL;
            this.old_backendURL = old_backendURL;
        }
    }

    private async makeRequest(url: string, params?: Params, queryParams?: any): Promise<any> {
        const defaultHeaders = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };

        // check if lang exits in params, if not, dont add it to the query
        const lang = params?.lang ? params.lang : this.defaultLanguage;

        // if lang is not defined in params, don't add to query
        const finalQueryParams = { ...params };
        const queryString = new URLSearchParams(finalQueryParams).toString();

        const finalUrl = `${url}${queryString ? `?${queryString}` : ''}`;

        const fullParams = {
            headers: {
                ...defaultHeaders,
            },
        };



        try {
            const response = await fetch(finalUrl, fullParams);
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }

            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("image/png")) {
                const blob = await response.blob();
                return URL.createObjectURL(blob);
            } else {
                return await response.json();
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    private makeRequestPost(url: string, body: any, lang: string | null, contentType: string = 'application/json'): Promise<any> {
        let bodyContent: string;

        // Check the content type and format the body accordingly
        if (contentType === 'application/x-www-form-urlencoded') {
            bodyContent = new URLSearchParams(body).toString();
        } else {
            bodyContent = JSON.stringify(body);
        }
        // } else {
        //     throw new Error('Unsupported content type: ' + contentType);
        // }

        const headers = {
            'Content-Type': contentType,
            'Access-Control-Allow-Origin': '*',
        };

        const fullParams = {
            method: 'POST',
            headers: headers,
            body: bodyContent,
        };

        //only add lang if it is defined
        const finalUrl = lang ? `${url}?lang=${lang}` : url;

        return fetch(finalUrl, fullParams)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok: ' + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    login(username: string, password: string, useFormUrlEncoded: boolean): Promise<any> {
        const url = new URL("https://f4fbackend-prod.azurewebsites.net/auth/token");
        const contentType = useFormUrlEncoded ? 'application/x-www-form-urlencoded' : 'application/json';
        const body = { username, password };
        const lang = null

        return this.makeRequestPost(url.toString(), body, lang, contentType);
    }

    getCountries(lang: string) {
        const url = new URL(this.baseURL + "countries");
        return this.makeRequest(url.toString(), { lang: lang });
    }

    getProjectareas(countryCode: string, lang: string) {
        const url = new URL(`${this.baseURL}countries/${countryCode}/project_areas`);
        return this.makeRequest(url.toString(), { lang: lang });
    }

    getSites(projectarea: string, lang: string) {
        const url = new URL(`${this.baseURL}project_areas/${projectarea}/sites`);
        return this.makeRequest(url.toString(), { lang: lang });
    }

    getCountryInformation(countryCode: string) {
        const url = new URL(`${this.baseURL}countries/${countryCode}`);
        return this.makeRequest(url.toString(), { lang: "en" });
    }

    getProjectareaInformation(projectAreaCode: string) {
        const url = new URL(`${this.baseURL}/project_areas/${projectAreaCode}`);
        return this.makeRequest(url.toString(), { lang: "en" });
    }

    getSiteInformation(siteId: string, lang?: string) {
        if (!lang) lang = this.defaultLanguage;
        const url = new URL(`${this.baseURL}/sites/${siteId}`);
        console.log("Site information new api", url.toString());
        return this.makeRequest(url.toString(), { lang: lang });
    }

    getSatLayerInfo(siteId: string, lang: string) {
        const url = new URL(`${this.baseURL}sites/${siteId}/sat_layers`);
        return this.makeRequest(url.toString(), { lang: lang });
    }

    getDroneLayersForSite(site: string, lang: string) {
        const url = new URL(`${this.baseURL}sites/${site}/drone_layers`);
        return this.makeRequest(url.toString(), { lang: lang });
    }

    getLayerImage(img_link: string, lang: string) {
        const url = new URL(`${this.baseURL}${img_link}`);
        return this.makeRequest(url.toString(), { lang: lang })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    //yearly

    getS2YearlyIndexTrend(site_id: string, index: string, start_year: number, end_year: number, lang: string) {
        const requestBody = {
            "based_on": "single_scenes",
            "interval": "yearly",
            "temporal_reducing": "max",
            "spatial_reducing": "mean",
            "index": "ndvi",
            "date_start": `${start_year}-01-01`,
            "date_end": `${end_year}-12-31`,
        }

        const url = new URL(`${this.baseURL}sites/${site_id}/collections/sentinel-2-l2a/trends`);
        console.log("getS2YearlyIndexTrend url: ", url.toString());

        return this.makeRequestPost(url.toString(), requestBody, lang);
    }

    calculateS2IndexChangeLayer(siteId: string, index: string, start_year: number, end_year: number, lang: string) {
        const requestBody = {
            "based_on": "single_scenes",
            "ref_period_start": `${start_year}-01-01`,
            "ref_period_end": `${start_year}-12-31`,
            "target_period_start": `${end_year}-01-01`,
            "target_period_end": `${end_year}-12-31`,
            "temporal_reducing": "max",
            "index": "ndvi"
        }

        const url = new URL(`${this.baseURL}sites/${siteId}/collections/sentinel-2-l2a/changes`);
        return this.makeRequestPost(url.toString(), requestBody, lang);
    }

    //monthly

    getS2MonthlyIndex5YearTrend(site_id: string, index: string, lang: string) {
        let based_on = "";
        index == "ndvi" ? based_on = "single_scenes" : based_on = "max_ndvi_composite";

        const requestBody = {
            "based_on": based_on,
            "interval": "monthly",
            "temporal_reducing": "max",
            "spatial_reducing": "mean",
            "index": index,
            "date_start": "2019-07-01",
            "date_end": "2024-06-30"
        }

        return this.makeRequestPost(`${this.baseURL}sites/${site_id}/collections/sentinel-2-l2a/trends`, requestBody, lang);
    }

    getChirpsMonthlyRainfall5YearTrend(site_id: string, lang: string) {
        const requestBody = {
            "based_on": "single_scenes",
            "interval": "monthly",
            "temporal_reducing": "max",
            "spatial_reducing": "mean",
            "measurement": "rainfall",
            "date_start": "2019-07-01",
            "date_end": "2024-06-30"
        }

        return this.makeRequestPost(`${this.baseURL}sites/${site_id}/collections/rainfall_chirps_monthly/trends`, requestBody, lang);
    }

    getFireMonthlyFireCount5YearTrend(site_id: string, lang: string) {
        // const date_start = "2021-01-01"
        // const date_end = "2023-12-31"
        // "date_start": "2019-07-01",
        // "date_end": "2024-06-30"
        const date_start = "2019-07-01"
        const date_end = "2024-06-30"

        return this.makeRequest(`${this.baseURL}sites/${site_id}/fire_trend?interval=monthly&date_start=${date_start}&date_end=${date_end}`);
    }

    getFires(link: string, lang: string) {
        // const url = new URL(this.baseURL + "firms/geojson");
        // const url = new URL(`${this.baseURL}${link}`);
        const url1 = "https://f4fbackend-prod.azurewebsites.net/sites/mdg_giz_diana_15/nrt_firms_points_by_aoi"
        return this.makeRequest(link, {});

    }

    getSatStatBoard(siteId: string, lang: string) {
        const url = new URL(`${this.baseURL}sites/${siteId}/sat_stat_board`);
        return this.makeRequest(url.toString(), { lang: lang });
    }


    uploadGeojson(uploadType: string, selectedFile: any, lang: string) {
        switch (uploadType) {
            case "country":
                //todo: migrate

                // const url = `${this.baseURL}field/country/upload_countries/`;
                // const formData = new FormData();

                // if (selectedFile) {
                //     formData.append(
                //         "countries_vec_file",
                //         selectedFile,
                //     );
                // }
                // const headers = {
                //     'Content-Type': 'multipart/form-data'
                // }
                // axios.post(url, formData, { headers })
                //     .then((response: any) => console.log(response.data))
                //     .catch((err: any) => console.log("error", err.response.data.detail[0]));

                break;

            case "projectarea":
                // todo: add code
                break;

            case "site":
                // todo: add code
                break;
        }
    }
}

export const backendService = new BackendService(config.backendURL, config.old_backendURL);