import { useState, useEffect } from 'react';
import { backendService } from '../Services';
import { useLanguage } from '../components/Language/LanguageContext';
import { FlrSiteData } from '../Services/apiResponseInterfaces';

interface FlrSiteDataProps {
    site: string;
}

export const useGetFlrSiteData = ({ site }: FlrSiteDataProps) => {
    const [siteInfo, setSiteInfo] = useState<FlrSiteData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { language } = useLanguage();

    useEffect(() => {
        if (site) {
            setLoading(true);
            backendService
                .getSiteInformation(site, language)
                .then((response: FlrSiteData) => {
                    setSiteInfo(response);
                    setLoading(false);
                })
                .catch((err: any) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    }, [site, language]);

    return { siteInfo, loading };
};