import { Box, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import LayerSelectionCard from '../../Cards/LayerSelectionCard';
import DashboardCard from '../../Cards/DashboardCard.component';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import LayerSelectionWrapper from './LayerSelectionWrapper';
import { useEffect, useState } from 'react';
import { DroneStatistic } from '../../../Hooks/useGetDroneStatistics';
import getLayerSelectionCardLabel from '../../../Services/labeling.service';
import { DroneLayersList } from '../../../Services/apiResponseInterfaces';
import React from 'react';
import useTranslation from '../../Language/useTranslation';

// export interface DroneLayerInfo {
//     site_id: string
//     layer_type: string
//     recording_date: Date
//     processing_date: Date
//     path: string
//     bidx: Array<number> | null
//     rescale: Array<string> | null
//     nodata: number | null
//     colormap: string | null
//     unit: string | null
//     description: string | null
// }

interface DroneContentProps {
    droneLayerList: DroneLayersList,
    // Record<string, DroneLayerInfo | null>,
    selectedLayer: string | null,
    setSelectedLayer: React.Dispatch<React.SetStateAction<string | null>>,
    droneLoading: boolean
}

/**
 * Contains all elements in the Drone Tab.
 */

function DroneContent({ droneLayerList, selectedLayer, setSelectedLayer, droneLoading }: DroneContentProps) {

    const t = useTranslation();

    useEffect(() => {
        /**
         * When component is rendered, set Layer to orthophoto if no other Layer is selected
         */
        selectedLayer === "" && setSelectedLayer("uav_orthophoto");
    }, [])


    if (droneLoading) {
        //return loading spinner
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!droneLayerList || droneLayerList.length === 0) {
        return (
            <Typography variant="h6">
                {t.noData}
            </Typography>
        );
    }


    // return <></>
    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
            {
                droneLayerList.map((droneLayer, index) => (
                    <Stack key={index} direction="column" spacing={2} mt={1}>
                        <Grid item sx={{ width: 300, mr: 2 }}>
                            <LayerSelectionCard
                                datasetId={droneLayer.dataset_id}
                                name={droneLayer.name}
                                selectedLayer={selectedLayer}
                                setSelectedLayer={setSelectedLayer}
                                layerType={droneLayer.layer_type}
                                imgLink={droneLayer.img_link}
                                layerDescription={droneLayer.description}
                            />
                        </Grid>
                        <Grid item sx={{ width: 300, mr: 2 }}>
                            <DashboardCard
                                description={droneLayer?.layers?.[0]?.statistics?.[0]?.description}
                                number={droneLayer?.layers?.[0]?.statistics?.[0]?.text_value}
                            />
                        </Grid>
                    </Stack>
                ))
            }
        </Grid>
    );
}


export default React.memo(DroneContent);