import L from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect, useState } from 'react';
import { backendService } from '../../Services/backend.service';
import _ from "lodash";
import "leaflet.markercluster";
import { useLanguage } from "../Language/LanguageContext"

export default function FireMarker(props) {
    const map = useMap();
    const [jsonDataFire, setJsonDataFire] = useState(null);
    const { language } = useLanguage();
    console.log("FireMarker link", props.link);

    const COLORS_MARKER = [
        "#ec1a18",
        "#f05a1a",
        "#f38c1c",
        "#f6c61e",
        "#F7EC1E",
        "#EAE84F",
        "#D9D583",
        "#C8C1BD"
    ];

    useEffect(() => {

        backendService.getFires(props.link, language).then((fires) => { setJsonDataFire(fires["geometry"]) })
            .catch((e) => console.log(e));
    }, [props.selectedSite]);

    useEffect(() => {
        if (!map || !jsonDataFire) return;

        const markers = new L.GeoJSON(jsonDataFire, {
            pointToLayer: (feature, latlng) => {
                const dateDiff = (new Date() - new Date(feature.properties.acq_date)) / (1000 * 60 * 60 * 24);
                const dateDiffFloored = Math.floor(dateDiff);
                // console.log("DateDiff", dateDiff, dateDiffFloored);
                const colorIndex = dateDiffFloored < COLORS_MARKER.length ? dateDiffFloored : COLORS_MARKER.length - 1;
                return getFireMarker(COLORS_MARKER[colorIndex], latlng);
            },
        });

        const markersCluster = new L.MarkerClusterGroup({
            disableClusteringAtZoom: 2,
            iconCreateFunction: function (cluster) {
                var markers = cluster.getAllChildMarkers();
                var html = '<div class="circle">' + markers.length + '</div>';
                return L.divIcon({ html: html, className: 'mycluster', iconSize: L.point(32, 32) });
            },
            showCoverageOnHover: true, zoomToBoundsOnClick: true
        });

        markersCluster.addLayer(markers);

        const onZoom = () => {
            if (map.getZoom() < 11) {
                if (map.hasLayer(markers)) {
                    map.removeLayer(markers);
                }
                if (!map.hasLayer(markersCluster)) {
                    map.addLayer(markersCluster);
                }
            } else {
                if (map.hasLayer(markersCluster)) {
                    map.removeLayer(markersCluster);
                }
                if (!map.hasLayer(markers)) {
                    map.addLayer(markers);
                }
            }
        };

        map.addLayer(markersCluster);
        map.on('zoom', onZoom);

        return () => {
            map.removeLayer(markersCluster);
            map.removeLayer(markers);
            map.off('zoom', onZoom);
        };
    }, [jsonDataFire, map]);

    return null;
}

function getFireMarker(color, latlng) {
    return L.marker(latlng, {
        icon: L.divIcon({
            html: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="${color}"><path d="M0 0h24v24H0z" fill="none"/><path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/></svg>`,
            className: "",
            iconAnchor: [12, 12],
        })
    });
}


// function getFiresTest() {
//     // const url = "https://firms.azurewebsites.net/firms/geojson";
//     const url = "https://f4fbackend-test.azurewebsites.net/sites/mdg_giz_diana_15/nrt_firms_points_by_aoi"

//     const fullParams = {}
//     if ([null, undefined].includes(fullParams["headers"])) {
//         fullParams["headers"] = {
//             "content-type": "application/json",
//             "Access-Control-Allow-Origin": "*",
//         };
//     } else {
//         fullParams["headers"]["content-type"] = "application/json";
//         fullParams["headers"]["Access-Control-Allow-Origin"] = "*";
//     }
//     console.log(url, fullParams);

//     return fetch(url, fullParams)
//         .then((response) => {
//             return response.json()
//         })
//         .then((data) => {
//             return data
//         })
//         .catch((error) => {
//             console.log(error);
//             throw error;
//         });
// }
