import { useEffect, useState } from "react";
import { backendService } from "../Services";
import { useLanguage } from "../components/Language/LanguageContext";


interface Site {
    code: string;
    name: string;
}

interface SiteDict {
    [id: string]: string;  // Maps site ID to display name
    //Todo: Add more site properties
}

export function useGetSiteList(selectedProjectarea: string | null) {
    const [siteDict, setSiteDict] = useState<SiteDict>({});
    const { language } = useLanguage();

    useEffect(() => {
        if (selectedProjectarea) {
            backendService.getSites(selectedProjectarea, language)
                .then((sites) => {
                    console.log("Sites", sites)
                    const newSiteDict = sites.reduce((acc: SiteDict, site: any) => {
                        acc[site.id] = site.display_name;  // Only use id and display_name
                        return acc;
                    }, {});
                    setSiteDict(newSiteDict);
                })
                .catch((e) => console.log("Error when resolving site list", e));
        } else {
            setSiteDict({});  // Clear the dictionary if no project area is selected
        }
    }, [selectedProjectarea, language]);

    return siteDict;

}
