import './App.scss';
import type { } from '@mui/lab/themeAugmentation'; // When using TypeScript 4.x and above
import { amber, grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        customBackground: Palette['primary'];
        iconInner: Palette['primary'];
        redCustom: Palette['primary'];
        greenCustom: Palette['primary'];
        orangeCustom: Palette['primary'];
        blueCustom: Palette['primary'];
    }

    interface PaletteOptions {
        customBackground: PaletteOptions['primary'];
        iconInner: PaletteOptions['primary'];
        redCustom: PaletteOptions['primary'];
        greenCustom: PaletteOptions['primary'];
        orangeCustom: PaletteOptions['primary'];
        blueCustom: PaletteOptions['primary'];
    }
}

const themeMainColor = "#94c11f"

const getDesignTokens = (mode: PaletteMode) => ({
    // Currently only dark mode is used
    components: {
        //make buttons to background color "#121212"

        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#302c2c",
                    color: "white",

                    "&:hover": {
                        color: themeMainColor,
                        backgroundColor: "#302c2c",
                    },
                },
            },
        },

        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: "#6b6b6b #2b2b2b",
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "#2b2b2b",
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 8,
                        backgroundColor: "#6b6b6b",
                        minHeight: 24,
                        border: "3px solid #2b2b2b",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        backgroundColor: "#2b2b2b",
                    },
                },
            },
        },
    },

    palette: {
        mode,
        ...(mode === 'dark'
            ? {
                // palette values for dark mode
                primary: {
                    main: themeMainColor
                    // main: "#65C18C"

                },
                // divider: deepOrange[700],
                background: {
                    // default: "#1D1D1D",
                    paper: "#1D1D1D",
                },
                text: {
                    primary: '#fff',
                    secondary: grey[500],
                },
                customBackground: {
                    main: "#121212"
                },
                iconInner: {
                    main: "#FFFFFF"
                },
                redCustom: {
                    main: "#D14343"
                },
                greenCustom: {
                    main: "#14B8A6"
                },
                orangeCustom: {
                    main: "#FFB020"
                },
                blueCustom: {
                    main: "#55AAFF"
                },

            } : {
                // palette values for light mode
                primary: amber,
                divider: amber[200],
                text: {
                    primary: grey[900],
                    secondary: grey[800],
                },

                customBackground: {
                    main: "#F0F0F0"
                    // main: "#121212"

                },
                iconInner: {
                    main: "#FFFFFF"
                },
                redCustom: {
                    main: "#D14343"
                },
                greenCustom: {
                    main: "#14B8A6"
                },
                orangeCustom: {
                    main: "#FFB020"
                },
                blueCustom: {
                    main: "#55AAFF"
                },
            }
        ),
    },
});

export { getDesignTokens };