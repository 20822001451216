import { backendService } from "../Services";

/**
 * Manages zoom behaviour when selecting a country, projectarea or site
 */

export class MapServices {

    zoomToPlace(code: string, setMapBounds: Function, placeType: string) {
        switch (placeType) {
            case "country":
                backendService.getCountryInformation(code).then((countryInfo: any) => {
                    setMapBounds([
                        [countryInfo.bounds[1], countryInfo.bounds[0]],
                        [countryInfo.bounds[3], countryInfo.bounds[2]],
                    ])
                })
                break;

            //todo: implement zoomToPlace for projectarea and site
            case "projectarea":
                backendService.getProjectareaInformation(code).then((projectareaInfo: any) => {
                    setMapBounds([
                        [projectareaInfo.bounds[1], projectareaInfo.bounds[0]],
                        [projectareaInfo.bounds[3], projectareaInfo.bounds[2]],
                    ])
                    console.log("Projectarea Info", projectareaInfo)
                })
                break;
            case "site":
                backendService.getSiteInformation(code).then((siteInformation: any) => {
                    setMapBounds([
                        [siteInformation.bounds[1], siteInformation.bounds[0]],
                        [siteInformation.bounds[3], siteInformation.bounds[2]],
                    ])
                })
                break;
        }
    }
}

export const mapService = new MapServices();
