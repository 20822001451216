import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ScatterChart, Scatter, Cell, ComposedChart } from 'recharts';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { calculateColorGradientValue } from '../../utils/colorUtils';
import createTrend from 'trendline';
import dayjs from 'dayjs';


interface ScatterChartContentProps {
    result: any,
    xAxisDataKey: string,
    yAxisDataKey: string,
    chartName: string,
    dataValueForLowColor: number,
    lowColor: string
    dataValueForHighColor: number,
    highColor: string,
    defaultColor: string,
    height: number,
    width: number
}

export default function ScatterChartContent({
    result,
    xAxisDataKey,
    yAxisDataKey,
    chartName,
    dataValueForLowColor,
    lowColor,
    dataValueForHighColor,
    highColor,
    defaultColor,
    height,
    width
}: ScatterChartContentProps) {
    const theme = useTheme();
    var timeseriesData: any[] = [];
    try {
        // convert result to dict so it can be shown with Recharts
        _.zipWith(result.data, result.time_steps, function (value: number, timestep: string) {
            const formattedTimestep = dayjs(timestep).format('MMM YYYY'); // Format the date to "Month Year"

            timeseriesData.push(
                {
                    [xAxisDataKey]: formattedTimestep,
                    [yAxisDataKey]: Math.round(value * 100) / 100,
                    color: calculateColorGradientValue(lowColor, highColor, dataValueForLowColor, dataValueForHighColor, value, defaultColor)
                }
            )
        });
    } catch (e: any) {
        console.log(e)
    }

    return (
        <ResponsiveContainer width="100%" height={height}>
            <ComposedChart
                // width={width}
                // height={height}
                margin={{
                    top: 5,
                    right: 30,
                    bottom: 5,
                }}
            >
                <CartesianGrid />
                <XAxis dataKey={xAxisDataKey} />
                <YAxis dataKey={yAxisDataKey} orientation="right" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter data={timeseriesData}
                    name={chartName} dataKey={yAxisDataKey} fill={defaultColor} >
                    {timeseriesData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Scatter>
            </ComposedChart>
        </ResponsiveContainer>
    );
}