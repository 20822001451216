import { Typography, Paper, Grid } from "@mui/material";
import React from "react";
import useTranslation from "../../Language/useTranslation";

/**
 * Styled Wrapper-Box that is used across components to show Layer Selection.
 */

export default function LayerSelectionWrapper({ children }: { children: React.ReactNode }) {
    const t = useTranslation();
    return (
        <>
            <Typography variant="h6" mb={2}>
                {t.bottomDrawer.satelliteData.layerWrapperTitle}
            </Typography>
            <Paper sx={{
                maxHeight: 250,
                overflow: 'auto',
                backgroundColor: 'customBackground.main',
                backgroundImage: 'none',
                width: '100%', // Ensure the parent container takes full width
            }}>
                <Grid container md={12} spacing={2} >
                    {children}
                </Grid>
            </Paper>
        </>
    )
}