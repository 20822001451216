import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { config } from "../../Config/config.service"
import { Grid } from '@mui/material';
import DashboardCard from '../Cards/DashboardCard.component';
import { useContext } from 'react';
import { useCountry } from '../../Contexts/useCountry';
import { useUserSelection } from '../../Contexts/useUserSelection';
import useTranslation from '../Language/useTranslation';
interface RightMainDrawerProps {
    open: boolean,
}

export default function RightMainDrawer({ open }: RightMainDrawerProps) {

    let countryInfoContent = null;
    const { countryInfoList } = useCountry();
    const t = useTranslation();


    const { selectedCountry } = useUserSelection();
    // if (!selectedCountry) return (<></>);





    // extract country information for selected country
    const countryInfo = countryInfoList.find((country) => country.code === selectedCountry);

    if (countryInfo) {
        countryInfoContent = <>
            <Grid container spacing={2} direction="column">
                <Grid item xs={12} md={3}>
                    <DashboardCard description={t.rightMainDrawer.numberOfProjectareas} number={countryInfo["num_project_areas"]} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard description={t.rightMainDrawer.numberOfSites} number={countryInfo["num_sites"]} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardCard description={t.rightMainDrawer.totalSiteArea} number={countryInfo["total_site_area_in_ha"]} />
                </Grid>
            </Grid>
        </>
    } else {
        countryInfoContent = <Typography variant='body1'>
            {t.rightMainDrawer.noCountryInfo}
        </Typography>
    }

    return (
        <>
            <SidebarDrawer
                transitionDuration={0}
                variant="persistent"
                anchor="right"
                open={true}
            >
                <Box m={4}>
                    <Typography variant='h6' mb={2}>
                        {t.rightMainDrawer.title}
                    </Typography>
                    {countryInfoContent}
                </Box>
            </SidebarDrawer>
        </>
    )
}


const SidebarDrawer = styled(Drawer, {
})({
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: config.general.rightDrawerWidthDesktop,
        height: config.general.leftDrawerHeight,
        boxSizing: 'border-box',
        borderRadius: "0px 0px 0px 8px"

    },
});
