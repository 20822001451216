import { Button, Stack, Box, InputLabel, MenuItem, FormControl, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react"
import { backendService } from "../../Services";
import { useLanguage } from "../Language/LanguageContext";

export function UploadDataControl() {
    const [uploadType, setUploadType] = useState("country")

    const handleInputTypeChange = (event: SelectChangeEvent) => {
        setUploadType(event.target.value as string);
    }
    return (<>
        <Box>
            {/* <Typography mb={2} variant="h6">Upload Data</Typography> */}
            <Box sx={{ minWidth: 120 }} mb={2}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Data Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={uploadType}
                        label="Select Data Type"
                        onChange={handleInputTypeChange}
                    >
                        <MenuItem value="country" key="country">Country</MenuItem>
                        <MenuItem value="projectarea" key="projectarea">Project Area</MenuItem>
                        <MenuItem value="site" key="site">Site</MenuItem>

                    </Select>
                </FormControl>
            </Box>
            <Stack direction="row" spacing={2}>
                <UploadFile uploadType={uploadType}></UploadFile>
            </Stack>
        </Box>
    </>)
}


function UploadFile({ uploadType }: { uploadType: string }) {
    const [selectedFile, setSelectedFile] = useState(null)
    const onFileChange = (event: any) => {
        event && setSelectedFile(event.target.files[0])
    }
    const { language } = useLanguage();
    const onFileUpload = () => {
        backendService.uploadGeojson(uploadType, selectedFile, language)
    };
    return (<>
        <Button
            variant="contained"
            component="label"
            onChange={onFileChange}
        >
            Choose file
            <input type="file" hidden />
        </Button>
        {/* <input type="file" onChange={onFileChange} /> */}
        <Button variant="contained" onClick={onFileUpload}>
            Upload
        </Button>

    </>)
}