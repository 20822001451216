import './App.scss';
import React from 'react';

import MainPage from './components/Main/MainPage';
import CssBaseline from '@mui/material/CssBaseline';

// When using TypeScript 4.x and above
import type { } from '@mui/lab/themeAugmentation';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { getDesignTokens } from './theme';
import { CountryProvider } from './Contexts/CountryContext';
import { UserSelectionProvider } from './Contexts/UserSelectionContext';
import { LanguageProvider } from './components/Language/LanguageContext';
import LanguageSwitcher from './components/Language/LanguageSwitcherComponent';
import { AuthProvider } from './Contexts/AuthContextProvider';

function App() {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  // const colorMode = React.useMemo(
  //   () => ({
  //     // The dark mode switch would invoke this method
  //     toggleColorMode: () => {
  //       setMode((prevMode: PaletteMode) =>
  //         prevMode === 'light' ? 'dark' : 'light',
  //       );
  //     },
  //   }),
  //   [],
  // );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <LanguageProvider>
            <UserSelectionProvider>
              <CountryProvider>
                <CssBaseline />
                <LanguageSwitcher />
                <MainPage />
              </CountryProvider>
            </UserSelectionProvider>
          </LanguageProvider>
        </LocalizationProvider>
      </ThemeProvider >
    </AuthProvider  >

  );
}

export default App;
