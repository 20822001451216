import React from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardContent, Grid } from '@mui/material';

interface DashboardCardProps {
    description: string,
    number: string | number | undefined | null
}

export default function DashboardCard({ description, number }: DashboardCardProps) {
    const value = number; // Renaming `number` to `value` internally
    const isLongValue = typeof value === 'string' && value.length > 40;

    return (
        <Card
            square={true}
            sx={{
                height: "130px",
                visibility: description ? 'visible' : 'hidden' // Make the card invisible if there's no description
            }}
        >
            <CardContent>
                <Grid item container direction="column" xs={12}>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                    >
                        {description || 'No description available'}
                    </Typography>

                    <Typography gutterBottom variant={isLongValue ? "subtitle2" : "h6"} component="div">
                        {typeof value === "number" ?
                            (Math.round(value * 100) / 100)
                            : // if not number -> str or undefined
                            ((value !== undefined && value !== null) ? value : "No Data")
                        }
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
}