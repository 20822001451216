import React, { createContext, ReactNode, FC } from 'react';
import { useGetCountryInfoList } from '../Hooks/useGetCountryInfoList';
import { CountryData } from '../Services/apiResponseInterfaces';

export interface CountryContextState {
    countryInfoList: CountryData[];
}

export const CountryContext = createContext<CountryContextState | undefined>(undefined);

interface CountryProviderProps {
    children: ReactNode;
}

export const CountryProvider: FC<CountryProviderProps> = ({ children }) => {
    const countryInfoList: CountryData[] = useGetCountryInfoList();

    return (
        <CountryContext.Provider value={{ countryInfoList }}>
            {children}
        </CountryContext.Provider>
    );
};