import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import LayerSelectionCard from '../../../Cards/LayerSelectionCard';
import LayerSelectionWrapper from './../LayerSelectionWrapper';
import { SatLayerList } from '../../../../Services/apiResponseInterfaces';
import useTranslation from '../../../Language/useTranslation';
import { CalculatedLayer } from '../../../../Services/apiResponseInterfaces';
import { SatRasterLayerInfo } from '../../../../Services/apiResponseInterfaces';
import { useLanguage } from '../../../Language/LanguageContext';

interface LayerSelectionProps {
    satLayerList: SatLayerList | null | undefined;
    satLoading: boolean;
    selectedLayer: string | null;
    setSelectedLayer: React.Dispatch<React.SetStateAction<string | null>>;
    calculatedLayer: CalculatedLayer | null;
}

const LayerSelection: React.FC<LayerSelectionProps> = ({ satLayerList, satLoading, selectedLayer, setSelectedLayer, calculatedLayer }) => {

    const t = useTranslation();
    const { language } = useLanguage();
    // const calculatedLayer: SatRasterLayerInfo | null = null;

    const calculated_sat_layer = {
        dataset_id: "NDVI_CHANGE_SINCE_PROJECT_START",
        name: "Test1",
        description: "This map shows the change of vegetation conditions between the project start year (provided in the dashboard under “site data”) and the last completed year. It bases on a “peak-of-season” comparison between years, through a cloud-based analysis of all available Sentinel-2 images of both years. The map provides an overview of positive and negative vegetation changes in the area in 10m pixel resolution to show the progress of project activities. For instance, afforestation activities should ideally lead to a clear positive vegetation change over time, while successful forest conservation activities should have no negative vegetation changes or slightly positive vegetation changes (depending on the initial condition of the forest in the area). In addition, the dashboard provides for each completed year since project start the area average of a vegetation index to show inter-year variations.",
        region_id: "dummy_region_id",
        region_level: "dummy_region_level",
        layer_type: "dummy_layer_type",
        value_type: "dummy_value_type",
        format: "dummy_format",
        provider: "dummy_provider", // Optional
        attribution: "dummy_attribution", // Optional
        all_touched_clipping: false,
        buffer_in_m: 50, // Optional
        resolution: "dummy_resolution", // Optional
        unit: "dummy_unit", // Optional
        bidx: [1, 2, 3], // Optional
        nodata: -9999, // Optional
        colormap_name: "dummy_colormap", // Optional
        legend: [
            {
                label: "Dummy Legend Item 1",
                value: 1,
                color: "#FF0000",
            },
            {
                label: "Dummy Legend Item 2",
                value: 2,
                color: "#00FF00",
            },
        ], // Optional
        layers: [
            {
                dataset_id: "layer_dummy_id",
                name: "test",
                region_id: "layer_dummy_region_id", // Optional
                region_level: "layer_dummy_region_level", // Optional
                date_str: "2024-08-20",
                link: "http://example.com/dummy-layer",
                id: 1, // Optional
                path_id: "layer_dummy_path_id", // Optional
                buffered: true, // Optional
                rescale: ["0", "255"], // Optional
            },
        ],
        img_link: "http://dummyimage.com/600x400", // Optional
    };

    // if calculatedLayer changes, add to satLayerList as layer
    useEffect(() => {
        if (satLayerList !== undefined && satLayerList !== null && calculatedLayer !== null) {
            console.log("calculatedLayer: ", calculatedLayer);
            //push dummy Object to top of list
            calculated_sat_layer.layers[0].link = calculatedLayer.link
            calculated_sat_layer.layers[0].dataset_id = t.bottomDrawer.satelliteData.calculatedLayer.name
            calculated_sat_layer.name = t.bottomDrawer.satelliteData.calculatedLayer.name
            //only push if not already in list
            if (!satLayerList.some(layer => layer.dataset_id === calculated_sat_layer.dataset_id)) {
                //push to top
                satLayerList.unshift(calculated_sat_layer);
            }

        }
    }, [calculatedLayer, satLayerList, language]);

    let isNew = false;

    return (
        <Grid container item xs={2}>
            {satLoading ? (
                // Loading spinner
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            ) : satLayerList === undefined || satLayerList === null || satLayerList.length === 0 ? (
                // No data available
                <Typography variant="h6">
                    {t.bottomDrawer.satelliteData.layerWrapperNoData}
                </Typography>
            ) : (
                // Data available, render layer cards
                <LayerSelectionWrapper >

                    {satLayerList.map((layer, index) => (
                        <Grid item xs={12} key={index} >

                            {
                                layer.dataset_id === "NDVI_CHANGE_SINCE_PROJECT_START" ?
                                    isNew = true : isNew = false
                            }


                            <LayerSelectionCard

                                datasetId={layer.dataset_id}
                                selectedLayer={selectedLayer}
                                setSelectedLayer={setSelectedLayer}
                                layerType={layer.layer_type}
                                imgLink={layer.img_link}
                                layerDescription={layer.description}
                                name={layer.name}
                                isNew={isNew}
                            />
                        </Grid>
                    ))}
                </LayerSelectionWrapper>
            )}
        </Grid>
    );
};

export default LayerSelection;
