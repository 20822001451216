import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Stack } from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from '@mui/material/styles';
import { backendService } from '../../../../Services';
import { SatLayerList, CalculatedLayer } from '../../../../Services/apiResponseInterfaces';
import useTranslation from '../../../Language/useTranslation';
import LayerSelection from './LayerSelection.component';
import TrendTypeSelector from './TrendTypeSelector.component';
import ChartBox from './ChartBox.component';
import SatStatistics from './SatStatistics.component';
import { useLanguage } from '../../../Language/LanguageContext';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import LayerInfo from '../../../Dialog/LayerInfo';
import TrendInfoDialog from '../../../Dialog/TrendInfo';

interface SatelliteContentProps {
    site: string;
    satLayerList: SatLayerList;
    satLoading: boolean;
    selectedLayer: string | null;
    setSelectedLayer: React.Dispatch<React.SetStateAction<string | null>>;
    calculatedLayer: CalculatedLayer | null;
    setCalculatedLayer: React.Dispatch<React.SetStateAction<CalculatedLayer | null>>;
}

const SatelliteContent: React.FC<SatelliteContentProps> = ({
    site,
    satLayerList,
    satLoading,
    selectedLayer,
    setSelectedLayer,
    calculatedLayer,
    setCalculatedLayer,
}) => {
    const [trendType, setTrendType] = useState('NDVI_CHANGE_SINCE_PROJECT_START');
    const [chartResult, setChartResult] = useState<any>(null);
    const [calcLoading, setCalcLoading] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const theme = useTheme();
    const { language } = useLanguage();

    const handleChange = (event: any) => {
        setChartResult(null);
        // setCalculatedLayer(null);
        setTrendType(event.target.value as string);
        // setSelectedLayer(event.target.value as string);

    };

    const handleClickInfo = (e: React.MouseEvent) => {
        setShowInfo(true);
        e.stopPropagation();
    };


    useEffect(() => {
        setChartResult(null);
        setCalculatedLayer(null);
    }, [site]);

    const t = useTranslation();

    const sendRequest = () => {
        setCalcLoading(true);

        switch (trendType) {
            case "NDVI_CHANGE_SINCE_PROJECT_START":
                fetchData(site, "ndvi", "project_start", "NDVI_CHANGE_SINCE_PROJECT_START");
                break;
            case "NDVI":
                fetchTrendData(site, "ndvi", "NDVI");
                break;
            case "NDWI":
                fetchTrendData(site, "ndwi", "NDWI");
                break;
            case "Rainfall":
                fetchRainfallData(site, "Rainfall");
                break;
            case "FIRE":
                fetchFireData(site, "FIRE");
                break;
            default:
                console.log("No valid trend type selected");
                setCalcLoading(false);
                break;
        }
    };

    const fetchData = async (site: string, index: string, start: string, layerName: string) => {
        try {
            const siteInfo = await backendService.getSiteInformation(site);
            const ndviData = await backendService.getS2YearlyIndexTrend(site, index, siteInfo[start], new Date().getFullYear() - 1, language);
            setChartResult(ndviData);

            const calculatedData = await backendService.calculateS2IndexChangeLayer(site, index, siteInfo[start], new Date().getFullYear() - 1, language);
            const calculatedLayer: CalculatedLayer = {
                name: `${index}_change_from_${start}`,
                link: calculatedData.link,
            };
            setCalculatedLayer(calculatedLayer);
            setSelectedLayer(layerName);
            console.log("selectedLayer", selectedLayer);
        } catch (err) {
            console.log(err);
        } finally {
            setCalcLoading(false);
        }
    };

    const fetchTrendData = async (site: string, index: string, layerName: string) => {
        try {
            const data = await backendService.getS2MonthlyIndex5YearTrend(site, index, language);
            setChartResult(data);
        } catch (err) {
            console.log(err);
        } finally {
            setCalcLoading(false);
        }
    };

    const fetchRainfallData = async (site: string, layerName: string) => {
        try {
            const data = await backendService.getChirpsMonthlyRainfall5YearTrend(site, language);
            setChartResult(data);
        } catch (err) {
            console.log(err);
        } finally {
            setCalcLoading(false);
        }
    };

    const fetchFireData = async (site: string, layerName: string) => {
        try {
            const data = await backendService.getFireMonthlyFireCount5YearTrend(site, language);
            setChartResult(data);
        } catch (err) {
            console.log(err);
        } finally {
            setCalcLoading(false);
        }
    };

    return (
        <>
            {showInfo && (
                <TrendInfoDialog
                    setShowInfo={setShowInfo}

                />
            )}
            <Stack direction="row" alignItems="flex-start" spacing={2}>
                <LayerSelection
                    satLayerList={satLayerList}
                    satLoading={satLoading}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    calculatedLayer={calculatedLayer}
                />
                <Grid container item xs={5} spacing={2} >
                    <Grid item xs={12} container direction="row" alignItems="flex-start" justifyContent="flex-start" >
                        <Grid mr={2}>
                            <TrendTypeSelector trendType={trendType} handleChange={handleChange} calcLoading={calcLoading} />
                        </Grid>
                        <Grid mr={2} >
                            <Button
                                variant="outlined"  // Change to outlined for no fill, just a border
                                sx={{
                                    padding: '6px 8px', minWidth: 'auto', height: 46,
                                    backgroundColor: 'transparent',
                                    borderColor: '#515151',

                                }}  // Match the height exactly
                                onClick={handleClickInfo}
                            >
                                <InfoIcon />
                            </Button>
                        </Grid>
                        <Grid >
                            <LoadingButton
                                loading={calcLoading}
                                variant="contained"
                                onClick={sendRequest}
                                sx={{ height: 46 }}  // Explicitly set the height
                            >
                                {t.bottomDrawer.satelliteData.calculateButton}
                            </LoadingButton>
                        </Grid>





                    </Grid>

                    <Grid item xs={12}>
                        <ChartBox trendType={trendType} chartResult={chartResult} />
                    </Grid>
                </Grid>
                <Grid item xs={5} sx={{ height: "300px" }}>
                    <SatStatistics />
                    {/* Additional content will go here */}
                </Grid>
            </Stack>
        </>
    );
};





export default SatelliteContent;



