import { MapContainer, LayersControl, TileLayer, useMap, useMapEvents, LayerGroup } from "react-leaflet";
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { ShapeLayer } from './Layers/ShapeLayer.component';
import { SelectedShapeLayer } from './Layers/SelectedShapeLayer.component';
import PopupShapeInfo from '../Dialog/PopupShapeInfo';
import { config } from '../../Config/config.service';
import { useDidMountEffect } from "../../Hooks/useDidMountEffect";
import { LatLngBounds } from "leaflet";
import { CalculatedLayer } from "../../Services/apiResponseInterfaces";
// import { SatelliteLayerInfo } from "../BottomDrawer/Tabs/SatelliteContent.component";

// import { DroneLayerInfo } from "../BottomDrawer/Tabs/DroneContent.component";
import DynamicLegend from "./DynamicLegend.component";
import CategoricalLegend from "./CategoricalLegend.component";
import ErrorBoundary from "../Errors/ErrorBoundary.component";
import { Box, Paper } from "@mui/material";
import esaWorldcoverLegendImage from "../../Images/Legends/esaWorldcover.png"
import L from "leaflet";
import { DroneLayersList } from '../../Services/apiResponseInterfaces';
// import { MarkerClusterGroup } from 'leaflet.markercluster';
import FireMarker from "./Fire";


import { SatLayerList, SatRasterLayerInfo } from "../../Services/apiResponseInterfaces";
import { useUserSelection } from "../../Contexts/useUserSelection";
import { backendService } from "../../Services";
import _ from "lodash";

import { GeoJsonObject } from 'geojson';


interface MapProps {
    selectedLayer: string | null,
    setSelectedLayer: React.Dispatch<React.SetStateAction<string | null>>,
    calculatedLayer: CalculatedLayer | null,
    // droneRasterLayerInfo: Record<string, DroneLayerInfo | null>,
    droneLayerList: any,
    droneLoading: boolean,
    satLayerList: SatLayerList,
    satLoading: boolean,
    mapBounds: LatLngBounds,
    setMapBounds: React.Dispatch<React.SetStateAction<LatLngBounds>>,
}


export default function Map({ selectedLayer,
    setSelectedLayer,
    calculatedLayer,
    droneLayerList: droneRasterLayerInfo,
    droneLoading,
    satLayerList,
    satLoading,

    mapBounds,
    setMapBounds }: MapProps) {

    const [showPopup, setShowPopup] = useState(false)
    const [polygonInfo, setPolygonInfo] = useState("")
    const darkMapUrl = 'https://tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=w7H4g1VuWIMDKmPD1T9gSOwzq2e66DHC369fOYTt1ci3yRT85IFu5qQ5hhTymMKZ'

    // Get user selection from context
    const { selectedCountry, setSelectedCountry, selectedProjectarea, setSelectedProjectarea, selectedSite, setSelectedSite } = useUserSelection();



    function GetCurrentZoom() {
        const [zoomLevel, setZoomLevel] = useState(5); // initial zoom level provided for MapContainer

        const mapEvents = useMapEvents({
            zoomend: () => {
                setZoomLevel(mapEvents.getZoom());
            },
        });

        console.log(zoomLevel);

        return null
    }


    const [activeLayer, setActiveLayer] = useState(selectedSite ? "Satellite" : "Dark");

    useEffect(() => {
        // Update the active layer based on the site value
        setActiveLayer(selectedSite ? "Satellite" : "Dark");
        setSelectedLayer(null)
    }, [selectedSite]);


    return (
        <>
            {selectedLayer && selectedSite && (
                <>
                    {(selectedLayer === "sat_lulc" || selectedLayer === "sat_active_fires") ? (
                        <CategoricalLegend
                            satLayerList={satLayerList}
                            selectedLayer={selectedLayer}
                        />
                    ) : (
                        <DynamicLegend
                            droneLayerList={droneRasterLayerInfo}
                            satLayerList={satLayerList}
                            selectedLayer={selectedLayer}
                        />
                    )}
                </>
            )}


            <Grid item className="map-container" xs={12} >
                <MapContainer className="map-container" center={config.map.startMapView} zoom={config.map.startMapZoom} scrollWheelZoom={true} zoomControl={false}>
                    <MapInteraction mapBounds={mapBounds} />

                    {selectedLayer && <TileLayer
                        key={selectedLayer}
                        zIndex={20}
                        url={getSelectedLayerUrl(selectedLayer, droneRasterLayerInfo, satLayerList)}
                    />}

                    {/* {(calculatedLayer !== null && calculatedLayer !== undefined) && <TileLayer
                        key={calculatedLayer.name}
                        zIndex={21}
                        url={`${config.backendURL}${calculatedLayer.link}`}
                    />} */}

                    {/* Countries Shape Layer*/}
                    <>
                        <ShapeLayer
                            layerUrl={`${config.backendURL}vec_tiles/collections/public.countries/tiles/{z}/{x}/{y}`}
                            regionType={"country"}
                            idProppertyName={"code"}
                            zIndexOffset={0}
                            setMapBounds={setMapBounds}
                        />
                        <SelectedShapeLayer
                            layerUrl={`${config.backendURL}vec_tiles/collections/public.countries/tiles/{z}/{x}/{y}`}
                            regionType={"country"}
                            idProppertyName={"code"}
                            zIndexOffset={1}
                            selectedRegion={selectedCountry}
                            setSelectedRegion={setSelectedCountry}
                            setMapBounds={setMapBounds}
                        /> </>

                    {/* Project Areas Shape Layer */}
                    {selectedCountry &&
                        <>
                            <ShapeLayer
                                layerUrl={`${config.backendURL}vec_tiles/collections/public.project_areas/tiles/{z}/{x}/{y}`}
                                regionType={"projectarea"}
                                idProppertyName={"code"}
                                zIndexOffset={2}
                                setMapBounds={setMapBounds}
                            />
                            <SelectedShapeLayer
                                layerUrl={`${config.backendURL}vec_tiles/collections/public.project_areas/tiles/{z}/{x}/{y}`}
                                regionType={"projectarea"}
                                idProppertyName={"code"}
                                zIndexOffset={3}
                                selectedRegion={selectedProjectarea}
                                setSelectedRegion={setSelectedProjectarea}
                                setMapBounds={setMapBounds}
                            />
                        </>}

                    {/* Site Shape Layer */}
                    {selectedProjectarea &&
                        <>
                            <ShapeLayer
                                layerUrl={`${config.backendURL}vec_tiles/collections/public.flrsites/tiles/{z}/{x}/{y}`}
                                regionType={"site"}
                                idProppertyName={"id_code"}
                                zIndexOffset={4}
                                setMapBounds={setMapBounds}
                            />
                            <SelectedShapeLayer
                                layerUrl={`${config.backendURL}vec_tiles/collections/public.flrsites/tiles/{z}/{x}/{y}`}
                                regionType={"site"}
                                idProppertyName={"id_code"}
                                zIndexOffset={5}
                                selectedRegion={selectedSite}
                                setSelectedRegion={setSelectedSite}
                                setMapBounds={setMapBounds}
                            />
                        </>
                    }

                    {/* {showPopup && <PopupShapeInfo
                        polygonInfo={polygonInfo}
                        setShowPopup={setShowPopup} />} */}
                    {/* Basemaps */}
                    {/* Fire */}
                    {console.log("selectedLayer:", selectedLayer)}
                    {selectedLayer === "sat_active_fires" &&
                        <FireMarker
                            key={`fire-marker-${selectedLayer}`}
                            selectedSite={selectedSite}
                            link={getSelectedLayerUrl(selectedLayer, droneRasterLayerInfo, satLayerList)}>
                        </FireMarker>
                    }
                    {/* } */}

                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked={activeLayer === "Dark"} name="Dark">
                            <TileLayer
                                url={darkMapUrl}
                                attribution='<a href="https://www.jawg.io" target="_blank">&copy; Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a> contributors'
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer checked={activeLayer === "Light"} name="Light">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer checked={activeLayer === "Satellite"} name="Satellite">
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                            />
                        </LayersControl.BaseLayer>
                    </LayersControl>

                </MapContainer>
            </Grid>
        </>);
}

// migrated /////////////////////////////
function getLayerLink(dataset_id: string, dataSat: SatLayerList, dataDrone: DroneLayersList, layerNumber = 0): string | null {
    // todo: currently first layer taken, future => Choose by date with drop down @layerNumber
    // @layerNumber=0

    // Search in dataSat
    let dataSatEntry = dataSat.find(d => d.dataset_id === dataset_id);
    if (dataSatEntry && dataSatEntry.layers && dataSatEntry.layers.length > 0) {
        console.log("dataSatEntry link:", dataSatEntry.layers[0].link)
        return dataSatEntry.layers[layerNumber].link;
    }

    // Search in dataDrone if not found in dataSat
    let dataDroneEntry = dataDrone.find(d => d.dataset_id === dataset_id);
    if (dataDroneEntry && dataDroneEntry.layers && dataDroneEntry.layers.length > 0) {
        console.log("dataDroneEntry link:", dataDroneEntry.layers[0].link)
        return dataDroneEntry.layers[layerNumber].link;
    }

    return null;
}

function getSelectedLayerUrl(selectedLayer: string, droneRasterLayerInfo: any, satLayerList: any) {

    const url = getLayerLink(selectedLayer, satLayerList, droneRasterLayerInfo);

    console.log("Selected Layer URL:", url)

    return (`${config.backendURL}${url}`)

}
/////////////////////////////

function MapInteraction({ mapBounds }: { mapBounds: LatLngBounds }) {
    const map = useMap()
    useDidMountEffect(() => {
        map.fitBounds(mapBounds, { padding: [50, 50] })
    }, [mapBounds])
    return null
}


// function getUrlForCalculatedLayer(url: string) {
//     // TODO: store vis info in DB and retrieve together with layer URL 
//     // for now, hardcoded values are OK, since we're only visualizing NDVI change analysis layers
//     return `${config.backendURL}cog/tiles/WebMercatorQuad/{z}/{x}/{y}.png?url=${url}&nodata=-9999&rescale=-0.5,0.5&colormap_name=rdylgn`;
// }

