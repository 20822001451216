import React, { useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { backendService } from '../Services/backend.service';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            setIsAuthenticated(true);
        }
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const response = await backendService.login(username, password, true);
            if (response.access_token) {
                console.log(response)
                localStorage.setItem('access_token', response.access_token);
                setIsAuthenticated(true);
            } else {
                throw new Error('Invalid credentials');
            }
        } catch (err) {
            console.error('Login error:', err);
            throw err;
        }
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};