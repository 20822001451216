import { useEffect, useState } from "react";
import { backendService } from "../Services";
import { useLanguage } from "../components/Language/LanguageContext";

interface ProjectDict {
    [code: string]: string;
}

export function useGetProjectareaList(selectedCountry: string | null) {
    const [projectareasInfo, setProjectareasInfo] = useState<ProjectDict | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { language } = useLanguage();

    useEffect(() => {
        if (selectedCountry) {
            setIsLoading(true);
            backendService.getProjectareas(selectedCountry, language)
                .then((projectareas) => {
                    const projectAreasDict = projectareas.reduce((acc: ProjectDict, item: { name: string, code: string }) => {
                        acc[item.code] = item.name;
                        return acc;
                    }, {});
                    setProjectareasInfo(projectAreasDict);
                    setIsLoading(false);
                })
                .catch((e) => {
                    console.log("Error fetching project areas:", e);
                    setIsLoading(false);
                });
        } else {
            setProjectareasInfo(null);
            setIsLoading(false);
        }
    }, [selectedCountry, language]);

    return { projectareasInfo, isLoading };
}