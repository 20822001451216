// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./components/Map/MapIcons/layers_black.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".map-container {\n  padding: 0;\n  min-height: 100vh !important;\n}\n\n.leaflet-container {\n  background: \"#302c2c\";\n  outline: 0;\n}\n\n.leaflet-top {\n  margin-right: 16.6667%;\n}\n\n.leaflet-control-layers-toggle {\n  background-color: #201c1c;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;\n  border-radius: 4px;\n  width: 56px !important;\n  height: 56px !important;\n}\n\n.leaflet-control-layers {\n  border: 0px !important;\n}\n\nbody::-webkit-scrollbar, body *::-webkit-scrollbar {\n  border-radius: 8px !important;\n}", "",{"version":3,"sources":["webpack://./src/components/Map/Map.scss","webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,4BAAA;ACCJ;;ADGA;EACI,qBAAA;EACA,UAAA;ACAJ;;ADGA;EACI,sBAAA;ACAJ;;ADGA;EACI,yBAAA;EACA,oEAAA;EACA,kBAAA;EACA,sBAAA;EACA,uBAAA;ACAJ;;ADKA;EAEI,sBAAA;ACHJ;;AApBA;EACI,6BAAA;AAuBJ","sourcesContent":[".map-container {\n    padding: 0;\n    min-height: 100vh !important;\n    // overflow: hidden !important;\n}\n\n.leaflet-container {\n    background: \"#302c2c\";\n    outline: 0;\n}\n\n.leaflet-top {\n    margin-right: 16.6667%;\n}\n\n.leaflet-control-layers-toggle {\n    background-color: #201c1c;\n    background-image: url(./MapIcons/layers_black.svg) !important;\n    border-radius: 4px;\n    width: 56px !important;\n    height: 56px !important;\n    //give it an outline with this color #94c11f\n    // border: 2px solid #94c11f;\n}\n\n.leaflet-control-layers {\n    // Layer\n    border: 0px !important;\n}\n\n// backgroundColor: \"#302c2c\",\n// color: \"#94c11f\",","@import './components/Map/Map.scss';\n\n\n\nbody::-webkit-scrollbar, body *::-webkit-scrollbar {\n    border-radius: 8px !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
