import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import useTranslation from '../Language/useTranslation';

interface TrendInfoDialogProps {
    setShowInfo: (show: boolean) => void;
}

const TrendInfoDialog: React.FC<TrendInfoDialogProps> = ({ setShowInfo }) => {
    const [open, setOpen] = React.useState(true);
    const t = useTranslation(); // Access translations using the hook

    const handleClose = () => {
        setOpen(false);
        setShowInfo(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="trend-info-dialog-title"
            aria-describedby="trend-info-dialog-description"
        >
            <DialogTitle id="trend-info-dialog-title">
                {t.bottomDrawer.satelliteData.trendInfoHeadline}
            </DialogTitle>
            <DialogContent dividers>
                {Object.entries(t.bottomDrawer.satelliteData.trendInfo).map(([key, section]) => {
                    // Explicitly type the section as the expected object structure
                    const typedSection = section as {
                        title: string;
                        description: string;
                    };

                    return (
                        <React.Fragment key={key}>
                            <Typography variant="h6" gutterBottom>
                                {typedSection.title}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {typedSection.description}
                            </Typography>
                        </React.Fragment>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrendInfoDialog;
