import { Grid, Typography, Box } from "@mui/material";
import React, { useEffect } from "react";
import { SelectCountry } from "./SelectCountry";
import { SelectProjectarea } from "./SelectProjectarea";
import { SelectSite } from "./SelectSite";
import useTranslation from "../Language/useTranslation";
import { useUserSelection } from '../../Contexts/useUserSelection';
import ErrorBoundary from "../Errors/ErrorBoundary.component";

interface ShowDataControlProps {
    setMapBounds: React.Dispatch<React.SetStateAction<number[][]>>;
    setBottomDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRightMainDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ShowDataControl({
    setMapBounds,
    setBottomDrawerOpen,
    setRightMainDrawerOpen
}: ShowDataControlProps) {

    const {
        selectedCountry,
        setSelectedCountry,
        selectedProjectarea,
        setSelectedProjectarea,
        selectedSite,
        setSelectedSite
    } = useUserSelection();

    // Reset states when higher level changes
    useEffect(() => {
        setSelectedSite(null);
        setSelectedProjectarea(null);
        setBottomDrawerOpen(false);
    }, [selectedCountry, setSelectedProjectarea, setSelectedSite, setBottomDrawerOpen]);

    useEffect(() => {
        setSelectedSite(null);
    }, [selectedProjectarea, setSelectedSite]);

    useEffect(() => {
        if (selectedSite) {
            setBottomDrawerOpen(true);
        }
    }, [selectedSite, setBottomDrawerOpen]);



    const t = useTranslation();

    // Form
    let form = (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" mb={2}>
                    1. {t.leftMainDrawer.countrySelection.headline}
                </Typography>
                <SelectCountry setMapBounds={setMapBounds} />
            </Grid>

            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    mb={2}
                    style={{ color: selectedCountry === null ? 'grey' : 'inherit' }}
                >
                    2. {t.leftMainDrawer.projectareaSelection.headline}
                </Typography>
                <SelectProjectarea setMapBounds={setMapBounds} />
            </Grid>

            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    mb={2}
                    style={{ color: selectedCountry === null || selectedProjectarea === null ? 'grey' : 'inherit' }}
                >
                    3. {t.leftMainDrawer.siteSelection.headline}
                </Typography>
                <SelectSite setMapBounds={setMapBounds} />
            </Grid>
        </Grid>
    );

    return <ErrorBoundary>
        <Box mb={2}>{form}</Box>
    </ErrorBoundary>;
}