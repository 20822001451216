import { useState, useEffect } from 'react';
import { backendService } from '../Services/backend.service';
import { StatisticsSatellite, StatisticsSatelliteList } from '../Services/apiResponseInterfaces';

export function useGetStatisticsInfo(site: string | null, lang: string) {
    const [statistics, setStatistics] = useState<StatisticsSatelliteList>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // Only fetch data if site is selected
        if (!site) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await backendService.getSatStatBoard(site, lang);

                const parseStatistic = (data: any): StatisticsSatellite | null => {
                    if (data.stat_short_name === "histogram_tree_height") {
                        return null; // Skip this object
                    }
                    return {
                        stat_short_name: data.stat_short_name,
                        stat_long_name: data.stat_long_name,
                        operation: data.operation,
                        date: data.date,
                        description: data.description,
                        stat_class: data.stat_class,
                        min: data.min,
                        max: data.max,
                        unit: data.unit,
                        text_value: data.text_value,
                        labels: data.labels,
                        values: data.values,
                        colors: data.colors,
                        counts: data.counts,
                        areas_in_ha: data.areas_in_ha,
                        shares: data.shares,
                        sum: data.sum,
                        mean: data.mean,
                        ...data // Catch-all for unknown properties
                    };
                };

                const parseStatisticsList = (dataList: any[]): StatisticsSatelliteList => {
                    return dataList
                        .map(parseStatistic)
                        .filter((stat): stat is StatisticsSatellite => stat !== null); // Remove null entries
                };

                const parsedData = parseStatisticsList(data);
                setStatistics(parsedData);
            } catch (error) {
                console.error("Failed to fetch statistics data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [site, lang]);

    return { statistics, loading };
}