import Grid from '@mui/material/Grid';
import { Box, CircularProgress, Stack } from '@mui/material';
import DashboardCard from '../../Cards/DashboardCard.component';
import { FlrPieChart } from '../../Charts/FlrPieChart.component';
import useTranslation from '../../Language/useTranslation';
import { useGetFlrSiteData } from '../../../Hooks/useGetFlrSiteData';
import { getNestedValue } from '../../../utils/data';

interface FlrSiteDataContentProps {
    site: string;
}



export default function FlrSiteDataContent({ site }: FlrSiteDataContentProps) {
    const { siteInfo, loading } = useGetFlrSiteData({ site });
    const t = useTranslation();

    const cardObjects = [
        { attribute: 'name', label: t.bottomDrawer.flrSiteData.name },
        { attribute: 'area_in_ha', label: t.bottomDrawer.flrSiteData.area },
        { attribute: 'restoration_measure', label: t.bottomDrawer.flrSiteData.restorationMeasure },
        { attribute: 'project_start', label: t.bottomDrawer.flrSiteData.flrActivityStart },
        { attribute: 'restoration_measure_details.num_seedlings_per_ha', label: t.bottomDrawer.flrSiteData.numberTreesPlanted },
        { attribute: '------not exist yet------', label: t.bottomDrawer.flrSiteData.treeSurvivalRate },
        { attribute: 'latest_activity_status.name', label: t.bottomDrawer.flrSiteData.activityStatus },
        { attribute: 'land_ownership_type', label: t.bottomDrawer.flrSiteData.landOwnershipType },
    ];

    if (loading) {
        //return loading spinner
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!siteInfo) {
        return <div>Please select a site</div>;
    }

    return (
        <Stack direction="row" alignItems="flex-start" spacing={6}>
            <Grid container item spacing={2} md={8}>
                {cardObjects.map(({ attribute, label }, index) => {
                    const value = getNestedValue(siteInfo, attribute);
                    return (
                        <Grid item xs={12} md={3} key={index}>
                            <DashboardCard description={label} number={value ?? 'N/A'} />
                        </Grid>
                    );
                })}
            </Grid>
            <Grid
                container
                item
                md={4}
                justifyContent="center"
                alignItems="center"
                sx={{ height: 250, mt: 0 }}
                key="pie-chart"
            >
                <FlrPieChart siteInfo={siteInfo} />
            </Grid>
        </Stack>
    );
}