import { useState, useEffect } from 'react';
import { backendService } from "../Services";
import { useUserSelection } from '../Contexts/useUserSelection';
import { SatLayerList, SatRasterLayerInfo, Layer } from '../Services/apiResponseInterfaces';
import { useLanguage } from '../components/Language/LanguageContext';

export function useGetSatelliteRasterLayerInfo(selectedSite: string | null) {
    const [satLayerList, setSatLayerList] = useState<SatLayerList>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // const { selectedSite } = useUserSelection();
    const { language } = useLanguage();

    useEffect(() => {
        if (!selectedSite) {
            return;
        }

        const fetchSatelliteData = async (siteId: string) => {
            setLoading(true);
            try {
                const data = await backendService.getSatLayerInfo(siteId, language);
                const parseLayer = (data: any): Layer => ({
                    dataset_id: data.dataset_id,
                    region_id: data.region_id,
                    region_level: data.region_level,
                    date_str: data.date_str,
                    link: data.link,
                    id: data.id,
                    path_id: data.path_id,
                    buffered: data.buffered,
                    rescale: data.rescale,
                    img_link: data.img_link,
                    ...data // Catch-all for unknown properties
                });

                const parseSatRasterLayerInfo = (data: any): SatRasterLayerInfo => ({
                    dataset_id: data.dataset_id,
                    name: data.name,
                    description: data.description,
                    region_id: data.region_id,
                    region_level: data.region_level,
                    layer_type: data.layer_type,
                    value_type: data.value_type,
                    format: data.format,
                    provider: data.provider,
                    attribution: data.attribution,
                    all_touched_clipping: data.all_touched_clipping,
                    buffer_in_m: data.buffer_in_m,
                    resolution: data.resolution,
                    unit: data.unit,
                    bidx: data.bidx,
                    nodata: data.nodata,
                    colormap_name: data.colormap_name,
                    legend: data.legend,
                    imgLink: data.imgLink,
                    layers: data.layers.map(parseLayer),
                    ...data // Catch-all for unknown properties
                });

                const parseSatRasterLayerInfoList = (dataList: any[]): SatRasterLayerInfo[] => {
                    return dataList.map(parseSatRasterLayerInfo);
                };

                // Sort by dataset id
                data.sort((a: any, b: any) => (a.dataset_id > b.dataset_id) ? 1 : -1);

                setSatLayerList(parseSatRasterLayerInfoList(data));
            } catch (error) {
                console.error("Error fetching satellite layer info:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSatelliteData(selectedSite);
    }, [selectedSite, language]);

    return { satLayerList, loading };
}