import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useTranslation from '../../../Language/useTranslation';

interface TrendTypeSelectorProps {
    trendType: string;
    handleChange: (event: SelectChangeEvent) => void;
    calcLoading: boolean;
}

const TrendTypeSelector: React.FC<TrendTypeSelectorProps> = ({ trendType, handleChange, calcLoading }) => {
    const t = useTranslation();

    return (
        <FormControl sx={{ minWidth: 120, height: '46px' }} disabled={calcLoading}>
            <InputLabel id="demo-simple-select-label" >
                {t.bottomDrawer.satelliteData.trend}
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={trendType}
                label={t.bottomDrawer.satelliteData.trend}
                onChange={handleChange}
                sx={{
                    height: '46px',
                    lineHeight: '46px',
                    padding: '0 14px', // Ensures padding matches standard button padding
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <MenuItem value={"NDVI_CHANGE_SINCE_PROJECT_START"}>{t.bottomDrawer.satelliteData.calculations.vegetationStart}</MenuItem>
                <MenuItem value={"NDVI"}>{t.bottomDrawer.satelliteData.calculations.ndvi}</MenuItem>
                <MenuItem value={"NDWI"}>{t.bottomDrawer.satelliteData.calculations.ndwi}</MenuItem>
                <MenuItem value={"Rainfall"}>{t.bottomDrawer.satelliteData.calculations.rainfall}</MenuItem>
                <MenuItem value={"FIRE"}>{t.bottomDrawer.satelliteData.calculations.fire}</MenuItem>

            </Select>
        </FormControl>
    );
};

export default TrendTypeSelector;
