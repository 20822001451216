import * as React from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Container, Drawer } from '@mui/material';


interface MobileBottomDrawerProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  children: React.ReactNode
}

export default function MobileBottomDrawer({ open, setOpen, children }: MobileBottomDrawerProps) {
  const drawerBleeding = 56;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Box sx={{
        ".MuiDrawer-root > .MuiPaper-root": {
          height: `calc(50% - ${drawerBleeding}px+20px)`,
          overflow: "visible"
        }
      }}>

        <SwipeableDrawer
          sx={{
            '& .MuiDrawer-paper': {
              overflow: "visible",
              width: "100%",
              height: `calc(90% - ${drawerBleeding}px)`,
            }
          }}
          BackdropProps={{
            invisible: true,
          }}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -drawerBleeding + 5,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
              backgroundColor: "customBackground.main"
            }}
          >
            <Puller />
            <Container sx={{ p: 3.5, }}></Container>
          </Box>
          {children}
        </SwipeableDrawer>
      </Box>
    </>
  );
}


const Puller = styled(Box)(({ theme }) => ({
  width: 60,
  height: 6,
  backgroundColor: grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));