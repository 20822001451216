import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { LegendSimpleProps } from '../../Services/apiResponseInterfaces';
import { getSelectedDataset } from '../../utils/data';

export default function CategoricalLegend({
    satLayerList,
    selectedLayer }: LegendSimpleProps) {

    if (!satLayerList) return <></>;
    if (!selectedLayer) return <></>;

    const selectedSatLayer = getSelectedDataset(satLayerList, selectedLayer);

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    zIndex: 1000,
                    m: 2,
                    left: "20%",
                    ml: "15px",
                    width: "400px",
                }}
            >
                <Grid>
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            zIndex: 1000,
                            m: 1,
                            left: "25%",
                            backgroundColor: "background.paper",
                            borderRadius: 2,
                            flexDirection: 'column',
                            padding: '8px'
                        }}
                    >
                        {selectedLayer === "sat_active_fires" && (
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{
                                    wordWrap: 'break-word'
                                }}
                            >
                                Days <br />since<br />detection
                            </Typography>
                        )}
                        <table>
                            <tbody>
                                {selectedSatLayer?.legend?.map((item: any, index: number) => (
                                    <tr key={index}>
                                        <td>
                                            <Paper
                                                sx={{
                                                    width: "20px",
                                                    height: "15px",
                                                    backgroundColor: item.color,
                                                    m: 0.7,
                                                    borderRadius: 0,
                                                }}
                                                elevation={0}
                                            />
                                        </td>
                                        <td>
                                            <Typography variant="body2" color="text.secondary" mr={2}>
                                                {item.label}
                                            </Typography>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}