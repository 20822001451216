import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    Button,
    Alert,
    CircularProgress,
} from '@mui/material';
import { useAuth } from '../../Contexts/AuthContext';

interface LoginDialogProps {
    open: boolean;
    onClose: () => void;
}

const LoginDialog: React.FC<LoginDialogProps> = ({ open, onClose }) => {
    const [username, setUsername] = useState('F4FAdmin');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const { login } = useAuth();

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setError(null);
        setIsLoading(true); // Start loading

        try {
            await login(username, password);
            onClose();
        } catch (err) {
            setError('Login failed. Please check your credentials.');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }} onSubmit={handleFormSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="User Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        disabled={isLoading} // Disable input while loading
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={isLoading} // Disable input while loading
                    />
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                    <DialogActions>
                        <Button onClick={onClose} color="primary" disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" disabled={isLoading}>
                            Login
                        </Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default LoginDialog;