import * as React from 'react';
import { Typography, Box, Button, Divider, Grid, Tabs, Tab } from '@mui/material';
import { ShowDataControl } from './ShowDataControl.component';
import useTranslation from '../Language/useTranslation';
import { useTheme } from '@mui/material/styles';
import LoginDialog from '../Forms/LoginDialog';
import { useAuth } from '../../Contexts/AuthContext';
import { UploadDataControl } from './UploadDataControl.component';

export default function LeftMainDrawerContent(props: any) {
  const [tabNumber, setTabNumber] = React.useState(0);
  const [isLoginOpen, setIsLoginOpen] = React.useState(false);
  const { isAuthenticated, logout } = useAuth(); // Access the authentication state and logout function
  const t = useTranslation();
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabNumber(newValue);
  };

  const handleLoginOpen = () => {
    setIsLoginOpen(true);
  };

  const handleLoginClose = () => {
    setIsLoginOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {!isAuthenticated ? (
        <>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 1,
            }}
          >
            <Grid item xs>
              <Typography variant="h6">
                {t.leftMainDrawer.navBar.dataShow}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleLoginOpen}
              >
                Login
              </Button>
            </Grid>
          </Grid>

          <Divider />

          <Box sx={{ padding: 3 }}>
            <ShowDataControl {...props} />
          </Box>

          <LoginDialog open={isLoginOpen} onClose={handleLoginClose} />
        </>
      ) : (
        <>
          <Tabs
            value={tabNumber}
            onChange={handleChange}
            aria-label="data tabs"
            sx={{
              '& .MuiTab-root': {
                fontSize: (theme) => theme.typography.subtitle1.fontSize, // Set font size to subtitle1
              },
            }}
          >
            <Tab label={t.leftMainDrawer.navBarAdmin.dataShow} />
            <Tab label={t.leftMainDrawer.navBarAdmin.dataUpload} />
            <Tab label={t.leftMainDrawer.navBarAdmin.settings} />
          </Tabs>

          <Divider />

          <TabPanel value={tabNumber} index={0}>
            <ShowDataControl {...props} />
          </TabPanel>
          <TabPanel value={tabNumber} index={1}>
            <UploadDataControl {...props} />
          </TabPanel>
          <TabPanel value={tabNumber} index={2}>
            <Button
              variant="contained"
              color="error"
              onClick={logout} // Logs out the user
            >
              Logout
            </Button>
          </TabPanel>
        </>
      )}
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}