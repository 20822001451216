import React, { createContext, useState, ReactNode, FC, SetStateAction } from 'react';

interface UserSelectionContextState {
    selectedCountry: string | null;
    setSelectedCountry: React.Dispatch<React.SetStateAction<string | null>>,
    selectedProjectarea: string | null;
    setSelectedProjectarea: React.Dispatch<React.SetStateAction<string | null>>,
    selectedSite: string | null;
    setSelectedSite: React.Dispatch<React.SetStateAction<string | null>>,
}

export const UserSelectionContext = createContext<UserSelectionContextState | undefined>(undefined);

interface UserSelectionProviderProps {
    children: ReactNode;
}

export const UserSelectionProvider: FC<UserSelectionProviderProps> = ({ children }) => {
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [selectedProjectarea, setSelectedProjectarea] = useState<string | null>(null);
    const [selectedSite, setSelectedSite] = useState<string | null>(null);

    return (
        <UserSelectionContext.Provider value={{ selectedCountry, setSelectedCountry, selectedProjectarea, setSelectedProjectarea, selectedSite, setSelectedSite }}>
            {children}
        </UserSelectionContext.Provider>
    );
};
