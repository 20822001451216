import React, { useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { FlrSiteData } from '../../Services/apiResponseInterfaces';
import { getNestedValue } from '../../utils/data';
import { Typography } from '@mui/material';
import useTranslation from '../Language/useTranslation';

interface FlrPieChartProps {
    siteInfo: FlrSiteData;
}

export function FlrPieChart({ siteInfo }: FlrPieChartProps) {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const t = useTranslation();

    // Retrieve the relevant data from siteInfo
    const percentNativePlanted = getNestedValue(siteInfo, 'restoration_measure_details.percent_native_planted');
    const percentNonNativePlanted = getNestedValue(siteInfo, 'restoration_measure_details.percent_non_native_planted');

    // Use 50 as the default value if the retrieved values are null or undefined
    const nativePlantedValue = percentNativePlanted ?? 0;
    const nonNativePlantedValue = percentNonNativePlanted ?? 0;

    if (nativePlantedValue === 0 && nonNativePlantedValue === 0) {
        return <Typography variant='h6'>{t.noData}</Typography>;
    }



    // Dynamically generate the pie chart data
    const data = [
        { name: 'Native Species', value: nativePlantedValue, fillColor: "#94c11f" },
        { name: 'Non-Native Species', value: nonNativePlantedValue, fillColor: "#0496FF" },
    ];

    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };

    const onPieLeave = () => {
        setActiveIndex(null);
    };

    return (
        <ResponsiveContainer width="100%" height={250}>
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={90}
                    outerRadius={120}
                    onMouseEnter={onPieEnter}
                    onMouseLeave={onPieLeave}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${entry.name}`} fill={entry.fillColor} />
                    ))}
                </Pie>
                <Legend
                    align="right"
                    layout="vertical"
                    verticalAlign="middle"
                    payload={
                        data.map((entry, idx) => ({
                            value: entry.name,
                            type: 'square',
                            id: `ID${idx}`,
                            color: entry.fillColor,
                        }))
                    }
                />
                <Tooltip
                    formatter={(value: any) => `${value} %`}
                />
            </PieChart>
        </ResponsiveContainer>
    );
}
