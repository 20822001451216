import { MenuItem, InputLabel, Box, FormControl, Select, SelectChangeEvent, CircularProgress } from "@mui/material";

import React, { useContext, useEffect } from "react";
import { mapService } from "../../Services";
import { useGetCountryList } from "../../Hooks/useGetCountryList";  // Simplified import
import { CountryContext } from "../../GlobalStates/CountryContext";
import { useCountry } from "../../Contexts/useCountry";
import useTranslation from "../Language/useTranslation";
import { useUserSelection } from '../../Contexts/useUserSelection';
import { useLanguage } from "../Language/LanguageContext"


interface SelectCountryProps {
    setMapBounds: React.Dispatch<React.SetStateAction<number[][]>>;
}

export function SelectCountry({ setMapBounds }: SelectCountryProps) {

    const { language } = useLanguage();
    const { countryInfoList } = useCountry();
    const t = useTranslation();
    const { selectedCountry, setSelectedCountry, setSelectedProjectarea } = useUserSelection();

    // useEffect(() => {
    //     const { countryInfoList } = useCountry(); // Reload countryInfoList when language changes
    // }, [language]); 

    const countryDict = countryInfoList.reduce((acc, country) => {
        acc[country.code] = country.name;
        return acc;
    }, {} as { [key: string]: string });


    const handleChange = (event: SelectChangeEvent) => {
        const countryCode = event.target.value as string;
        setSelectedCountry(countryCode);
        // setSelectedProjectarea(null);
        mapService.zoomToPlace(countryCode, setMapBounds, "country");
    };


    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="country-select-label">{t.leftMainDrawer.countrySelection.selectionBox}</InputLabel>
                <Select
                    labelId="country-select-label"
                    id="country-select"
                    value={selectedCountry ? selectedCountry : ""}
                    label={t.leftMainDrawer.countrySelection.selectionBox}
                    onChange={handleChange}
                >
                    {/* if country dict empty, show spinner */}
                    {Object.keys(countryDict).length === 0 && <MenuItem disabled>
                        <CircularProgress size={24} />
                    </MenuItem>}
                    {Object.entries(countryDict).map(([code, name]: [string, string]) => (
                        <MenuItem value={code} key={code}>
                            {name.charAt(0).toUpperCase() + name.slice(1)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
