import { useEffect, useRef } from "react";

export function useDidMountEffect(func: any, deps: any) {
    // Prevents re-render on inital load
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}
