import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MobileBottomDrawer from './MobileBottomDrawer.component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DesktopBottomDrawer from './DesktopBottomDrawer.component';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FlrSiteDataContent from './Tabs/FlrSiteDataContent.component';
import SatelliteContent from './Tabs/SatelliteContent/SatelliteContent.component';
import DroneContent from './Tabs/DroneContent.component';
import { CalculatedLayer } from '../../Services/apiResponseInterfaces';
import { DroneStatistic } from '../../Hooks/useGetDroneStatistics';
import { SatLayerList } from '../../Services/apiResponseInterfaces';
import { useUserSelection } from '../../Contexts/useUserSelection';
import useTranslation from '../Language/useTranslation';
import { DroneLayersList } from '../../Services/apiResponseInterfaces';
import { Typography } from '@mui/material';

interface BottomDrawerProps {
    droneLayerList: DroneLayersList,
    droneLoading: boolean,
    satLayerList: SatLayerList,
    satLoading: boolean,
    // droneStatistics: Record<string, DroneStatistic | null>,
    selectedLayer: string | null,
    setSelectedLayer: React.Dispatch<React.SetStateAction<string | null>>,
    calculatedLayer: CalculatedLayer | null,
    setCalculatedLayer: React.Dispatch<React.SetStateAction<CalculatedLayer | null>>,
    bottomDrawerOpen: boolean,
    setBottomDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({
    droneLayerList,
    droneLoading,
    satLayerList,
    satLoading,
    selectedLayer,
    setSelectedLayer,
    calculatedLayer,
    setCalculatedLayer,
    bottomDrawerOpen,
    setBottomDrawerOpen,
}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tabNumber, setTabNumber] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabNumber(newValue);
    };

    const { selectedSite } = useUserSelection();
    const t = useTranslation();

    if (!selectedSite) return null;

    const content = (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabNumber} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
                    <Tab label={<Typography variant="subtitle1">{t.bottomDrawer.navBar.level1}</Typography>} />
                    <Tab label={<Typography variant="subtitle1">{t.bottomDrawer.navBar.level2}</Typography>} />
                    <Tab label={<Typography variant="subtitle1">{t.bottomDrawer.navBar.level3}</Typography>} />
                </Tabs>
            </Box>
            <TabPanel value={tabNumber} index={0}>
                <FlrSiteDataContent site={selectedSite} />
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
                <DroneContent
                    droneLayerList={droneLayerList}
                    droneLoading={droneLoading}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                />
            </TabPanel>
            <TabPanel value={tabNumber} index={2}>
                <SatelliteContent
                    site={selectedSite}
                    satLayerList={satLayerList}
                    satLoading={satLoading}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    calculatedLayer={calculatedLayer}
                    setCalculatedLayer={setCalculatedLayer}
                />
            </TabPanel>
        </Box>
    );

    return (
        <>
            {mobile ?
                <MobileBottomDrawer
                    open={bottomDrawerOpen}
                    setOpen={setBottomDrawerOpen}
                >
                    {content}
                </MobileBottomDrawer>
                :
                <DesktopBottomDrawer
                    open={bottomDrawerOpen}
                    setOpen={setBottomDrawerOpen}
                >
                    {content}
                </DesktopBottomDrawer>
            }
        </>
    );
};

export default React.memo(BottomDrawer);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};