import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { useGetStatisticsInfo } from '../../../../Hooks/useGetSatelliteStatistics';
import { StatisticsSatellite } from '../../../../Services/apiResponseInterfaces';
import { useUserSelection } from '../../../../Contexts/useUserSelection';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import DashboardCard from '../../../Cards/DashboardCard.component'
import LandCoverPieChart from '../../../Charts/LandCoverPieChart.component';
import { useLanguage } from '../../../Language/LanguageContext';
import useTranslation from '../../../Language/useTranslation';


const SatStatistics: React.FC = () => {
    const { selectedSite } = useUserSelection();
    const { language } = useLanguage();
    const t = useTranslation();

    const { statistics, loading } = useGetStatisticsInfo(selectedSite, language);

    if (loading) {
        //return loading spinner
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!statistics || statistics.length === 0) {
        return (
            <Typography variant="h6">
                {t.noData}
            </Typography>
        );
    }


    // Find the land cover statistics within the statistics array
    const landCoverStatistics = statistics.find((stat: StatisticsSatellite) => stat.stat_short_name === "pie_chart_landcover");

    // Filter out the land cover statistics from the general statistics
    const generalStatistics = statistics.filter((stat: StatisticsSatellite) => stat.stat_short_name !== "pie_chart_landcover");

    return (
        <Grid container spacing={2}>
            <Grid container item xs={12} sm={6} spacing={2}>
                {generalStatistics.map((stat: StatisticsSatellite, index: number) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <DashboardCard
                            description={stat.stat_long_name}
                            number={stat.text_value || stat.sum || stat.mean || stat.min || stat.max}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12} sm={6}>
                {landCoverStatistics && <LandCoverPieChart data={landCoverStatistics} height={250} />}
            </Grid>
        </Grid>
    );
};

export default SatStatistics;

