import { useEffect, useState } from 'react';
import { useLanguage } from './LanguageContext';
import defaultTranslation from './translations/en.json'
import { Translations } from './translations';

const useTranslation = () => {
  const { language } = useLanguage();
  const [translations, setTranslations] = useState<Translations>(defaultTranslation as Translations);

  useEffect(() => {
    import(`./translations/${language}.json`)
      .then((module: { default: Translations }) => {
        setTranslations(module.default);
      })
      .catch((error) => {
        console.error(`Could not load ${language}.json`, error);
      });
  }, [language]);

  return translations;
};

export default useTranslation;
