import React, { createContext, useState, useContext, useEffect } from 'react';

// Define the shape of the context's data
interface AuthContextData {
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

// Create the context with default values
export const AuthContext = createContext<AuthContextData | undefined>(undefined);

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextData => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};